import { HStack, Stack, Text } from '@chakra-ui/react';

import { ProviderSettings, ProviderWithImplementation } from '../types';
import { ProviderIcon } from '../../components/Iconagraphy/ProviderIcon';
import { ContentBox } from '../../components/ContentBox';
import { memo } from 'react';
import { ProviderSwitch } from './ProviderSwitch';
import { EditButton } from './EditButton';
import { PinProvider } from './PinProvider';
import { ConfigurationNeededToolTip } from './ConfigurationNeededToolTip';
import {
  getConfigurationNeededText,
  isProviderEnabled,
  isProviderToggleable,
} from '../utils';
import { UnsavedConfigIndicator } from '../../shared/icons/UnsavedConfigIndicator';
import { PERMISSION } from '../../constant/roles-and-permissions';
import { usePermissions } from '../../hooks/use-permissions';

export const ProviderEntry = memo(function ProviderEntry({
  provider,
  providerSettings,
  updateIsPinned,
  updateEnabled,
  isLastItem,
  setSelectedProviderId,
  hasStagedConfiguration,
}: {
  provider: ProviderWithImplementation;
  providerSettings: ProviderSettings;
  updateIsPinned: (id: string, pinnedIndex: boolean) => void;
  updateEnabled: (id: string, enabled: boolean) => void;
  setSelectedProviderId: (id: string) => void;
  hasStagedConfiguration: (id: string) => boolean;
  isLastItem: boolean;
}) {
  const providerEnabled = isProviderEnabled(provider);
  const disableToggle = !isProviderToggleable(provider, providerSettings);
  const configurationNeededText = getConfigurationNeededText(
    providerSettings,
    provider,
  );

  const roleCanUpdateConfigurations = usePermissions([
    PERMISSION.IntegrationConfigurations.Update,
  ]);

  return (
    <ContentBox
      key={provider.id}
      as="li"
      title={provider.displayName}
      sx={{ listStyle: 'none' }}
      borderRadius="0"
      p="16px 22px"
      mb={isLastItem ? '120px' : '0'}
      borderBottomRadius={isLastItem ? '8px' : '0'}
    >
      <Stack direction={{ base: 'column', lg: 'row' }} justify="space-between">
        <HStack opacity={providerEnabled ? 1 : 0.3}>
          <PinProvider
            provider={provider}
            updateIsPinned={updateIsPinned}
            enabled={providerEnabled && roleCanUpdateConfigurations}
            disableTooltip={!providerEnabled}
          />

          <ProviderIcon
            size="lg"
            src={provider.icon}
            provider={provider.displayName}
          />
          <Text>{provider.displayName}</Text>
          {hasStagedConfiguration(provider.id) && <UnsavedConfigIndicator />}
        </HStack>
        <HStack justify="flex-end" spacing="16px">
          {configurationNeededText && (
            <ConfigurationNeededToolTip
              text={configurationNeededText.tooltip}
            />
          )}
          <ProviderSwitch
            id={provider.id}
            checked={providerEnabled}
            onChange={() => updateEnabled(provider.id, !provider.enabled)}
            disabled={disableToggle || !roleCanUpdateConfigurations}
          />

          {roleCanUpdateConfigurations && (
            <EditButton onClick={() => setSelectedProviderId(provider.id)} />
          )}
        </HStack>
      </Stack>
    </ContentBox>
  );
});
