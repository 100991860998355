import styled from '@emotion/styled';

import { COLORS } from '../../constant';

import { Box, Flex, Stack, HStack } from '@chakra-ui/react';
import { ReactNode } from 'react';

export const ConnectionCard = styled.div`
  background: #ffffff;
  /* Drop Shadow Test */
  box-shadow: 0px 0px 1px rgba(22, 22, 22, 0.12),
    0px 4px 8px rgba(22, 22, 22, 0.06);
  border-radius: 8px;
`;

export const ConnectionCardHeader = ({ children }: { children: ReactNode }) => {
  return (
    <Box
      background={COLORS.GRAY.GRAY_200}
      height="48px"
      width="100%"
      borderTopRadius="8px"
    >
      <Flex
        justify="space-between"
        width="100%"
        height="100%"
        align="center"
        paddingLeft="24px"
        paddingRight="20px"
      >
        {children}
      </Flex>
    </Box>
  );
};

export const ConnectionCardBody = ({
  children,
  spacing,
}: {
  children: ReactNode;
  spacing?: string;
}) => {
  return (
    <Stack
      alignItems="center"
      paddingTop="16px"
      paddingBottom="24px"
      spacing={spacing}
    >
      {children}
    </Stack>
  );
};

export const ConnectionCardRow = ({ children }: { children: ReactNode }) => {
  return (
    <HStack width="100%" paddingLeft="24px" paddingRight="24px">
      {children}
    </HStack>
  );
};
