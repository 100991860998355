import { COLORS } from '../../constant';

import { AccountStatus } from '@finch-api/common/dist/external/dashboard/connection-status';
import { Box, HStack, Stack, Text, Alert, Divider } from '@chakra-ui/react';
import { ConnectionAccountDetail, ConnectionProductGroup } from '../types';
import startCase from 'lodash/startCase';

import { getConnectionGrouping } from '../utils';
import { ConnectionStatusTag } from '../../components/Tag/ConnectionStatusTag';
import {
  ConnectionCard,
  ConnectionCardHeader,
  ConnectionCardBody,
} from './ConnectionCard';
import { ConnectionType } from './ConnectionType';
import {
  DisconnectedIcon,
  NeedsAttentionIcon,
  PendingIcon,
  ConnectedIcon,
} from '../../components/Iconagraphy';

const ScopeTag = ({
  scope,
  disconnected,
}: {
  scope: string;
  disconnected?: boolean;
}) => {
  const opacity = disconnected ? 0.5 : 1.0;
  return (
    <Box
      opacity={opacity}
      p="2px 6px"
      borderRadius="6px"
      bg={COLORS.GRAY.GRAY_100}
      color={COLORS.GRAY.GRAY_600}
      border={`1px solid ${COLORS.GRAY.GRAY_400}`}
    >
      <Text fontSize="xs" fontWeight="500">
        {startCase(scope.replace('employer:', ''))}
      </Text>
    </Box>
  );
};

const ProductCategoryName = ({
  category,
  status,
}: {
  category: string;
  status: AccountStatus;
}) => {
  const renderIcon = () => {
    switch (status) {
      case AccountStatus.CONNECTED:
        return <ConnectedIcon />;
      case AccountStatus.DISCONNECTED:
        return <DisconnectedIcon />;
      case AccountStatus.PENDING:
        return <PendingIcon />;
      default:
        return <NeedsAttentionIcon />;
    }
  };
  return (
    <Box p="2px 6px">
      <HStack spacing={2}>
        {renderIcon()}
        <Text fontSize="14" fontWeight="400">
          {startCase(category)}
        </Text>
      </HStack>
    </Box>
  );
};

export const ProductReauthBanner = () => (
  <Alert variant="finchWarning" py="5px" px="8px">
    <Text
      fontFamily="Inter"
      fontWeight={500}
      fontSize="13px"
      color={COLORS.ORANGE}
    >
      This product is not syncing. The Employer needs to re-authenticate.
    </Text>
  </Alert>
);

export const ProductCategory = ({
  group,
}: {
  group: ConnectionProductGroup;
}) => (
  <Stack spacing="8px" width="100%" paddingLeft="24px" paddingRight="24px">
    <HStack justifyContent={'space-between'}>
      <ProductCategoryName
        category={group.productGroup}
        status={group.status}
      />
      <ConnectionType
        kind={group.category}
        disconnected={group.status === AccountStatus.DISCONNECTED}
      />
    </HStack>
    <Divider />
    <Stack spacing={2}>
      {group.reauthRequired && <ProductReauthBanner />}
      <HStack>
        {group.scopes.map((scope, index) => (
          <ScopeTag
            scope={scope}
            key={index}
            disconnected={group.status === AccountStatus.DISCONNECTED}
          />
        ))}
      </HStack>
    </Stack>
  </Stack>
);

export const ConnectionStatusCard = ({
  connectionDetail,
}: {
  connectionDetail: ConnectionAccountDetail;
}) => {
  const groupings = getConnectionGrouping(connectionDetail);
  return (
    <ConnectionCard>
      <ConnectionCardHeader>
        <Text
          fontFamily="Inter"
          fontStyle="normal"
          fontWeight="500"
          fontSize="14px"
          lineHeight="17px"
          color={COLORS.FINCH.BLACK}
        >
          Connection Status
        </Text>
        <ConnectionStatusTag status={connectionDetail.connectionStatus} />
      </ConnectionCardHeader>
      <ConnectionCardBody spacing="24px">
        {groupings.map((group, index) => (
          <ProductCategory group={group} key={index} />
        ))}
      </ConnectionCardBody>
    </ConnectionCard>
  );
};
