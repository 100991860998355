import {
  Spacer,
  Flex,
  Heading,
  Text,
  InputGroup,
  InputRightElement,
  Image,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  Tooltip,
  FormHelperText,
  FormControl,
} from '@chakra-ui/react';
import SuccessCircle from '../../../assets/images/icons/successCircle.svg';
import { COLORS } from '../../../constant';
import { ClipboardButton } from '../../../components/ClipboardButton';

export const ConnectUrlCopy = ({
  url,
  onClose,
  helperText = 'This URL is single-use and expires after 7 days. If you need to access this URL again, go to the Connection Detail view for this company.',
}: {
  url: string;
  onClose: () => void;
  helperText?: string;
}) => {
  return (
    <>
      <ModalBody>
        <Spacer height="36px" />
        <Flex>
          <Spacer />
          <Image src={SuccessCircle} width="64px" height="64px" />
          <Spacer />
        </Flex>
        <Spacer height="24px" />
        <Heading
          fontWeight="medium"
          fontSize="20px"
          lineHeight="26px"
          textAlign="center"
          marginBottom="8px"
        >
          Success!
        </Heading>
        <Text textAlign="center" marginBottom="30px">
          Send this URL to your customer to authorize their integrations
        </Text>

        <FormControl>
          <InputGroup>
            <Input
              fontSize="14px"
              padding="6px 10px"
              borderColor={COLORS.GRAY.GRAY_400}
              backgroundColor={COLORS.GRAY.GRAY_200}
              borderRadius="8px"
              color={COLORS.GRAY.GRAY_600}
              isReadOnly
              value={url ?? ''}
            />
            <Tooltip label="Copy URL" placement="top-end">
              <InputRightElement p="1">
                <ClipboardButton
                  backgroundColor="gray.c200"
                  size="sm"
                  value={url ?? ''}
                  _hover={{
                    backgroundColor: 'gray.c200',
                  }}
                />
              </InputRightElement>
            </Tooltip>
          </InputGroup>
          <FormHelperText color={COLORS.GRAY.GRAY_600} lineHeight="1.4">
            {helperText}
          </FormHelperText>
        </FormControl>
      </ModalBody>
      <ModalFooter>
        <Button
          w="full"
          variant="primary"
          onClick={onClose}
          h="52px"
          fontSize="16px"
        >
          Complete
        </Button>
      </ModalFooter>
    </>
  );
};
