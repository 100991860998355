import { Stack } from '@chakra-ui/react';
import {
  DndContext,
  type DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  restrictToVerticalAxis,
  restrictToWindowEdges,
} from '@dnd-kit/modifiers';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { Fragment } from 'react';
import { SortableProviderEntry } from './SortableProviderEntry';
import { ProviderSettings, ProviderWithImplementation } from '../types';

export const SortableProviderList = ({
  providers,
  providerSettings,
  onDragEnd,
  updateIsPinned,
  updateEnabled,
  setSelectedProviderId,
  hasStagedConfiguration,
}: {
  providers: ProviderWithImplementation[];
  providerSettings: ProviderSettings;
  onDragEnd: (event: DragEndEvent) => void;
  updateIsPinned: (id: string, pinnedIndex: boolean) => void;
  updateEnabled: (id: string, enabled: boolean) => void;
  setSelectedProviderId: (id: string) => void;
  hasStagedConfiguration: (id: string) => boolean;
}) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={onDragEnd}
      modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
    >
      <SortableContext items={providers} strategy={verticalListSortingStrategy}>
        <Stack
          overflow="scroll"
          h="full"
          spacing="0"
          as="ul"
          title="Integration list"
          sx={{ listStyle: 'none' }}
        >
          {providers.map((provider, index) => {
            const isLastItem = index === providers.length - 1;

            return (
              <Fragment key={provider.id}>
                <SortableProviderEntry
                  provider={provider}
                  providerSettings={providerSettings}
                  updateIsPinned={updateIsPinned}
                  updateEnabled={updateEnabled}
                  isLastItem={isLastItem}
                  setSelectedProviderId={setSelectedProviderId}
                  hasStagedConfiguration={hasStagedConfiguration}
                />
              </Fragment>
            );
          })}
        </Stack>
      </SortableContext>
    </DndContext>
  );
};
