import {
  Alert,
  AlertDescription,
  Box,
  Text,
  HStack,
  Icon,
} from '@chakra-ui/react';
import { COLORS } from '../../constant';
import { WarningIcon } from '../../shared/icons/WarningIcon';

export const TrinetWarning = () => {
  return (
    <Alert variant="finchWarning">
      <Box p="8px">
        <AlertDescription>
          <HStack align={'top'}>
            <Icon boxSize="6">
              <WarningIcon iconColor={COLORS.ORANGE} />
            </Icon>
            <Text
              fontSize="14px"
              lineHeight="21px"
              fontWeight="400"
              fontFamily="Inter"
            >
              You must be approved by TriNet and added to their developer center
              in order to use the API integration. If you enable this
              integration and you have not yet been approved, your customers
              will experience errors. If you have not been approved, please
              reach out to your Finch account representative.
            </Text>
          </HStack>
        </AlertDescription>
      </Box>
    </Alert>
  );
};
