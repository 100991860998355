import { Box, HStack, Text, Tooltip } from '@chakra-ui/react';
import { COLORS } from '../../constant';
import { InfoIcon } from '../../shared/InfoIcon';

export const AuthorizationTypeDisabled = ({
  authorizationType,
}: {
  authorizationType: string;
}) => {
  return (
    <HStack>
      <Tooltip
        label={`The ${authorizationType} Authentication Type is currently set to “Off” for all integrations. To turn this back on, go to Settings > Available Authentication Methods.`}
        placement="top"
      >
        <Box>
          <InfoIcon />
        </Box>
      </Tooltip>
      <Text
        fontFamily="Inter"
        fontWeight="500"
        fontSize="14px"
        lineHeight="20px"
        color={COLORS.GRAY.GRAY_600}
      >
        {' '}
        Disabled
      </Text>
    </HStack>
  );
};
