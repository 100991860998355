import { Box } from '@chakra-ui/react';
import { ScheduledMaintenanceBanner } from '../banners/ScheduledMaintenanceBanner';

import { NavigationBar } from './NavigationBar';
import { OrganizationApplications } from './OrganizationApplicationsContext';

export const PageLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <OrganizationApplications>
        <Box>
          <ScheduledMaintenanceBanner />
          <NavigationBar />
          {children}
        </Box>
      </OrganizationApplications>
    </>
  );
};
