import {
  AccountStatus,
  ConnectionCategory,
} from '@finch-api/common/dist/external/dashboard/connection-status';
import { ImplementationKind } from '@finch-api/common/dist/internal/connect/authorize';
import { Products } from '../constant/scopes';
import {
  ConnectionAccountDetail,
  ProductGroup,
  ConnectionProductGroup,
} from './types';

export const kindToTitle: Record<string, string> = {
  [ImplementationKind.API_TOKEN]: 'API',
  [ImplementationKind.FINCH_SANDBOX_API_TOKEN]: 'API',
  [ImplementationKind.OAUTH]: 'OAuth',
  [ImplementationKind.FINCH_SANDBOX_OAUTH]: 'OAuth',
  [ImplementationKind.CREDENTIAL]: 'Credentials',
  [ImplementationKind.FINCH_SANDBOX_CREDENTIAL]: 'Credentials',
  [ImplementationKind.ASSISTED]: 'Assisted',
  [ImplementationKind.FINCH_SANDBOX_ASSISTED]: 'Assisted',
};

const productToGrouping = {
  [Products.COMPANY]: ProductGroup.Organization,
  [Products.DIRECTORY]: ProductGroup.Organization,
  [Products.EMPLOYMENT]: ProductGroup.Organization,
  [Products.INDIVIDUAL]: ProductGroup.Organization,
  [Products.SSN]: ProductGroup.Organization,
  [Products.PAYMENT]: ProductGroup.Payroll,
  [Products.PAY_STATEMENT]: ProductGroup.Payroll,
  [Products.BENEFITS]: ProductGroup.Deductions,
  [Products.DEDUCTIONS]: ProductGroup.Deductions,
};

const groupOrder = [
  ProductGroup.Organization,
  ProductGroup.Payroll,
  ProductGroup.Deductions,
];

export const getConnectionGrouping = (
  connectionDetail: ConnectionAccountDetail,
) => {
  const { products } = connectionDetail;
  const grouping: Record<string, ConnectionProductGroup> = {};

  products.forEach((product) => {
    const category =
      product.implementationKind === ImplementationKind.ASSISTED
        ? ConnectionCategory.Assisted
        : ConnectionCategory.Automated;
    product.scopes.forEach((scope) => {
      const productGroup = productToGrouping[scope as Products];
      if (productGroup) {
        const existingGroup = grouping[productGroup];
        if (existingGroup) {
          existingGroup.scopes.push(scope);
        } else {
          grouping[productGroup] = {
            productGroup,
            category,
            scopes: [scope],
            status: product.status,
            reauthRequired: [
              AccountStatus.ERROR_NO_ACCOUNT_SETUP,
              AccountStatus.ERROR_PERMISSIONS,
              AccountStatus.REAUTH,
            ].includes(product.status),
          };
        }
      }
    });
  });

  return Object.values(grouping).sort(
    (a, b) =>
      groupOrder.indexOf(a.productGroup) - groupOrder.indexOf(b.productGroup),
  );
};
