export const DEFAULT_SCOPES = [
  'company',
  'directory',
  'individual',
  'employment',
  'payment',
  'pay-statement',
] as const;

export const PRODUCT_LABEL_MAP = new Map<string, string>(
  Object.entries({
    benefits: 'Benefits',
    company: 'Company',
    deduction: 'Deductions',
    directory: 'Directory',
    employment: 'Employment',
    individual: 'Individual',
    pay_statement: 'Pay Statement',
    payment: 'Payment',
    ssn: 'SSN',
  }),
);

export enum Products {
  BENEFITS = 'employer:benefits',
  DEDUCTIONS = 'employer:deduction',
  COMPANY = 'employer:company',
  DIRECTORY = 'employer:directory',
  EMPLOYMENT = 'employer:employment',
  INDIVIDUAL = 'employer:individual',
  PAYMENT = 'employer:payment',
  PAY_STATEMENT = 'employer:pay_statement',
  SSN = 'employer:ssn',
}
