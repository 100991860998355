import {
  Flex,
  SystemStyleObject,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { flexRender } from '@tanstack/react-table';
import type { HeaderGroup, Header, Column } from '@tanstack/react-table';

import {
  FiChevronDown as ChevronDown,
  FiChevronUp as ChevronUp,
  FiArrowDown as ArrowDown,
  FiArrowUp as ArrowUp,
} from 'react-icons/fi';

import { COLORS, Z_INDEX } from '../constant';
import { Spacer } from '../shared/Spacer';
import { CSSProperties } from 'react';

const ColumnName = <T,>({ header }: { header: Header<T, unknown> }) => {
  let weight = 400;
  let color = COLORS.GRAY.GRAY_600;

  if (header.column.getIsSorted()) {
    weight = 600;
    color = COLORS.FINCH.BLACK;
  }

  return (
    <Text
      as="span"
      width="full"
      color={color}
      fontWeight={weight}
      fontSize="14px"
    >
      {flexRender(header.column.columnDef.header, header.getContext())}
    </Text>
  );
};

const SortingIcon = <T,>({ column }: { column: Column<T, unknown> }) => {
  if (!column.getCanSort()) return null;

  return column.getIsSorted() ? (
    column.getIsSorted() === 'desc' ? (
      <ArrowDown color={COLORS.FINCH.BLACK} />
    ) : (
      <ArrowUp color={COLORS.FINCH.BLACK} />
    )
  ) : (
    <VStack spacing="-2">
      <ChevronUp color={COLORS.GRAY.GRAY_600} />
      <ChevronDown color={COLORS.GRAY.GRAY_600} />
    </VStack>
  );
};

export const getCommonPinningStyles = (column: Column<any>): CSSProperties => {
  const pinned = column.getIsPinned();

  if (!pinned) {
    return {
      position: 'relative',
      zIndex: Z_INDEX.BACKGROUND,
    };
  }

  return {
    boxShadow: '-4px 0 4px -4px gray inset',
    left: pinned === 'left' ? `${column.getStart('left')}px` : undefined,
    position: 'sticky',
    width: column.getSize(),
    zIndex: Z_INDEX.FOREGROUND,
  };
};

export const getCommonStickyStyles = (): CSSProperties => ({
  position: 'sticky',
  top: 0,
  zIndex: Z_INDEX.TABLE_STICKY_HEADER,
});

export const TableHeaderContent = <T,>({
  headerGroups,
  sticky = false,
  thOverrideStyle,
}: {
  headerGroups: HeaderGroup<T>[];
  sticky?: boolean;
  thOverrideStyle?: SystemStyleObject;
}) => {
  return (
    <Thead
      sx={{
        Th: {
          paddingY: '22px',
          ...thOverrideStyle,
        },
        p: {
          textTransform: 'capitalize',
          fontSize: '14px',
        },
      }}
    >
      {headerGroups.map((headerGroup) => (
        <Tr key={headerGroup.id}>
          {headerGroup.headers.map((header, index) => {
            return (
              <Th
                key={header.id}
                textTransform="capitalize"
                style={{
                  ...header.column.columnDef.meta?.style,
                  ...getCommonPinningStyles(header.column),
                  ...(sticky ? getCommonStickyStyles() : undefined),
                  ...(sticky && index === 0
                    ? { zIndex: 1 + Z_INDEX.TABLE_STICKY_HEADER }
                    : undefined),
                }}
                cursor={header.column.getCanSort() ? 'pointer' : ''}
                onClick={
                  header.column.getCanSort()
                    ? header.column.getToggleSortingHandler()
                    : () => {}
                }
              >
                <Flex alignItems="center">
                  <ColumnName header={header} />
                  {header.column.getCanSort() && (
                    <>
                      {' '}
                      <Spacer p="1" />
                      <SortingIcon column={header.column} />
                    </>
                  )}
                </Flex>
              </Th>
            );
          })}
        </Tr>
      ))}
    </Thead>
  );
};
