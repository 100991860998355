import { Stack } from '@chakra-ui/react';

import { Fragment } from 'react';
import { ProviderEntry } from './ProviderEntry';
import { ProviderSettings, ProviderWithImplementation } from '../types';

export const ProviderList = ({
  providers,
  providerSettings,
  updateIsPinned,
  updateEnabled,
  setSelectedProviderId,
  hasStagedConfiguration,
}: {
  providers: ProviderWithImplementation[];
  providerSettings: ProviderSettings;
  updateIsPinned: (id: string, pinnedIndex: boolean) => void;
  updateEnabled: (id: string, enabled: boolean) => void;
  setSelectedProviderId: (id: string) => void;
  hasStagedConfiguration: (id: string) => boolean;
}) => {
  return (
    <Stack
      as="ul"
      title="Integration list"
      overflow="scroll"
      h="full"
      spacing="0"
      width="100%"
    >
      {providers.map((provider, index) => {
        const isLastItem = index === providers.length - 1;

        return (
          <Fragment key={provider.id}>
            <ProviderEntry
              provider={provider}
              providerSettings={providerSettings}
              updateIsPinned={updateIsPinned}
              updateEnabled={updateEnabled}
              isLastItem={isLastItem}
              setSelectedProviderId={setSelectedProviderId}
              hasStagedConfiguration={hasStagedConfiguration}
            />
          </Fragment>
        );
      })}
    </Stack>
  );
};
