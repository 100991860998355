import {
  Box,
  Switch as ChakraSwitch,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';
import { COLORS } from '../constant';

export const Switch = ({
  enabled,
  onChange,
  isDisabled = false,
  labels = { enabled: 'Enabled', disabled: 'Disabled' },
  id,
}: {
  enabled: boolean;
  isDisabled?: boolean;
  id: string;
  labels?: { enabled: string; disabled: string };
  onChange: (enabled: boolean) => void;
}) => {
  return (
    <Box>
      <FormControl display="flex" alignItems="center">
        <FormLabel
          htmlFor={`is-${id}-enabled`}
          mb="0"
          fontSize="sm"
          mr="8px"
          opacity={isDisabled ? 0.5 : 1}
          color={enabled ? 'success.600' : COLORS.GRAY.GRAY_600}
        >
          {enabled ? labels.enabled : labels.disabled}
        </FormLabel>
        <ChakraSwitch
          id={`is-${id}-enabled`}
          isDisabled={isDisabled}
          isChecked={enabled}
          w="40px"
          onChange={(e) => onChange(e.target.checked)}
        />
      </FormControl>
    </Box>
  );
};
