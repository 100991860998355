'use client';

import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Center,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { SideNavigationWithContent } from 'shared/SideNavigationWithContent';

import { useProxyToApiServer } from '../hooks/use-proxy-to-api-server';
import { useApiSchema } from '../hooks/use-api-schema';
import { OperationsList } from '../components/operations-list';
import { Request } from '../components/request';
import { Response } from '../components/response';
import { useSelectedOperation } from '../store/selected-operation';
import { OperationTitle } from '../components/operation-title';

export const ApiExplorer = () => {
  const {
    isLoading: isSchemaLoading,
    error: schemaError,
    refetch: refetchApiSchema,
  } = useApiSchema();

  const methods = useForm();

  const selectedOperation = useSelectedOperation(
    (state) => state.selectedOperation,
  );

  const { makeRequest, requestId, serverResponse, isFetching, error } =
    useProxyToApiServer({ formContext: methods });

  if (schemaError) {
    return (
      <Alert status="error">
        <AlertIcon />
        <Text>
          Failed to fetch the API schema. Make sure the API server is running!
        </Text>
        <Button
          onClick={() => refetchApiSchema()}
          size="sm"
          ml="auto"
          variant="solid"
          colorScheme="blackAlpha"
        >
          Retry
        </Button>
      </Alert>
    );
  }

  return isSchemaLoading ? (
    <Center flex="1" h="50vh">
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
        size="xl"
      />
    </Center>
  ) : (
    <Stack
      spacing="24px"
      h={{
        base: 'calc(100vh - 320px)',
        xl: 'calc(100vh - 350px)',
      }}
    >
      <FormProvider {...methods}>
        <SideNavigationWithContent
          nav={
            <Box p="2" overflowY="scroll" maxH="100%">
              <OperationsList />
            </Box>
          }
          containerProps={{
            p: '2',
            templateRows: { base: '2fr 5fr', md: '1fr' },
          }}
          navProps={{ maxH: 'full', overflowY: 'scroll' }}
          mainContentProps={{ h: 'full', overflowY: 'hidden', p: '3' }}
          wrapMainContentInContentBox
        >
          {selectedOperation ? (
            <Stack h="full">
              <OperationTitle />
              <Stack h="full" spacing="4" overflow="scroll">
                <Request
                  fetch={makeRequest}
                  isLoading={isFetching}
                  error={error}
                />
                <Response
                  serverResponse={serverResponse}
                  requestId={requestId}
                />
              </Stack>
            </Stack>
          ) : (
            <Center h="100%">
              <Text>Select an operation to view the request and response</Text>
            </Center>
          )}
        </SideNavigationWithContent>
      </FormProvider>
    </Stack>
  );
};
