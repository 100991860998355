import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { baseURL } from '../../utils/baseUrls';
import {
  ImplementationInfo,
  ProviderWithImplementation,
  StagedOAuthCredentials,
  Tab,
} from '../types';
import { ImplementationKind } from '@finch-api/common/dist/internal/connect/authorize';
import { useStagedProviderConfigurationsStore } from '../state/stagedConfigurations';
import {
  getImplementations,
  mergeServerAndStagedConfigurations,
} from '../utils';
import { useMemo } from 'react';
import type { DragEndEvent } from '@dnd-kit/core';
import { useProviderSearchStore } from '../state/providerSearch';
import { useSelectedTabStore } from '../state/selectedTab';
import { useProviderSettings } from './useProviderSettings';

export const useProviderConfigurations = () => {
  const selectedTab = useSelectedTabStore((store) => store.selectedTab);

  const { getAccessTokenSilently } = useAuth0();

  const { applicationId } = useParams<{ applicationId: string }>();

  const searchTerm = useProviderSearchStore((state) => state.searchTerm);

  const {
    stagedOAuthCredentials,
    stagedConfigurations,
    setStagedConfiguration,
    setStagedOAuthCredentials,
    selectedProviderId,
    setSelectedProviderId,
    pinnedProviderIds,
    pinProvider,
    unpinProvider,
    setProviderPinnedIndex,
    setOriginalConfigurations,
    hasStagedConfiguration,
    hasChanges,
  } = useStagedProviderConfigurationsStore();

  const { providerSettings } = useProviderSettings();

  const { data, isLoading, error } = useQuery({
    queryKey: ['providerConfigurations', applicationId],
    refetchOnMount: false,
    queryFn: async () => {
      return axios
        .get<ProviderWithImplementation[]>(
          `${baseURL}/api/v1/applications/${applicationId}/provider-configurations`,
          {
            headers: {
              Authorization: `Bearer ${await getAccessTokenSilently()}`,
            },
          },
        )
        .then((res) => {
          setOriginalConfigurations(res.data);
          return res.data;
        });
    },
  });

  const unfilteredProviders = useMemo(() => {
    return mergeServerAndStagedConfigurations({
      stagedConfigurations,
      serverConfigurations: data || [],
    });
  }, [data, stagedConfigurations]);

  const allProviders = useMemo(() => {
    return unfilteredProviders.filter((provider) =>
      provider.displayName
        .toLowerCase()
        .includes(searchTerm?.toLowerCase() || ''),
    );
  }, [unfilteredProviders, searchTerm]);

  const automatedProviders = useMemo(() => {
    return allProviders.filter((provider) =>
      provider.implementations.some(
        (implementation) => implementation.kind !== ImplementationKind.ASSISTED,
      ),
    );
  }, [allProviders]);

  const assistedProviders = useMemo(() => {
    return allProviders.filter((provider) =>
      provider.implementations.every(
        (implementation) => implementation.kind === ImplementationKind.ASSISTED,
      ),
    );
  }, [allProviders]);

  const newProviders = useMemo(
    () =>
      allProviders.filter((provider) =>
        provider.implementations.every((implementation) =>
          dayjs(implementation.launchedAt).isAfter(
            dayjs().subtract(30, 'days'),
          ),
        ),
      ),
    [allProviders],
  );

  const updateIsPinned = async (providerId: string, pinned: boolean) => {
    if (pinned) {
      pinProvider(providerId);
    } else {
      unpinProvider(providerId);
    }
  };

  const handlePinnedProviderDrag = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active.id !== over?.id) {
      const newIndex = pinnedProviderIds.indexOf(over?.id as string);
      setProviderPinnedIndex(active.id as string, newIndex);
    }
  };

  const toggleProviderImplementation = (
    implementationKind: string,
    enabled: boolean,
  ) => {
    if (!selectedProviderId) return;

    const selectedProvider = allProviders.find(
      (provider) => provider.id === selectedProviderId,
    );

    if (!selectedProvider) return;

    const implementation = selectedProvider.implementations.find(
      (implementation) => implementation.kind === implementationKind,
    );

    if (!implementation) return;

    setStagedConfiguration(selectedProviderId, {
      ...selectedProvider,
      implementations: selectedProvider.implementations.map((implementation) =>
        implementation.kind === implementationKind
          ? { ...implementation, enabled }
          : implementation,
      ),
    });
  };

  const shouldAllowUpdatesToImplementation = (
    provider: ProviderWithImplementation,
    implementation: ImplementationInfo,
  ) => {
    if (!providerSettings) return false;
    const isAllowedImplementation =
      providerSettings.allowedImplementationKinds.includes(
        implementation.kind as ImplementationKind,
      );

    if (!isAllowedImplementation) return false;

    if (
      implementation.kind === ImplementationKind.OAUTH &&
      provider.requireOAuthCredentialsInput &&
      !provider.oauthCredentials &&
      !stagedOAuthCredentials.get(provider.id)
    )
      return false;

    return true;
  };

  const updateEnabled = async (providerId: string, enabled: boolean) => {
    const configuration = allProviders.find(
      (provider) => provider.id === providerId,
    );

    if (!configuration) return;

    const implementations = getImplementations(configuration);

    // If there is only one implementation, toggle the provider implementation
    if (implementations.length === 1) {
      const impl = implementations[0];
      if (impl && shouldAllowUpdatesToImplementation(configuration, impl)) {
        setStagedConfiguration(providerId, {
          ...configuration,
          enabled,
          implementations: configuration.implementations.map((implementation) =>
            implementation.kind === impl.kind
              ? { ...implementation, enabled }
              : implementation,
          ),
        });
      }
    } else {
      setStagedConfiguration(providerId, {
        ...configuration,
        enabled,
      });
    }
  };

  const updatePrimaryImplementation = (implementationId: string) => {
    if (!selectedProviderId) return;

    const selectedProvider = allProviders.find(
      (provider) => provider.id === selectedProviderId,
    );

    if (!selectedProvider) return;

    const primaryImplementation = selectedProvider.implementations.find(
      (implementation) => implementation.id === implementationId,
    );

    if (!primaryImplementation) return;

    setStagedConfiguration(selectedProviderId, {
      ...selectedProvider,
      defaultImplementation: primaryImplementation.kind,
      defaultImplementationId: primaryImplementation.id,
    });
  };

  const addOAuthCredentials = (credentials: StagedOAuthCredentials) => {
    if (!selectedProviderId) return;

    const selectedProvider = allProviders.find(
      (provider) => provider.id === selectedProviderId,
    );

    if (!selectedProvider) return;

    setStagedOAuthCredentials(selectedProviderId, credentials);
  };

  const selectedProvider =
    allProviders.find((provider) => provider.id === selectedProviderId) || null;

  const clearSelectedProvider = () => {
    setSelectedProviderId(null);
  };

  const unfilteredPinnedProviders = useMemo(
    () =>
      pinnedProviderIds
        .map((id) => {
          const provider = unfilteredProviders.find(
            (provider) => provider.id === id,
          );
          return provider;
        })
        .filter(
          (provider) => provider !== undefined,
        ) as ProviderWithImplementation[],

    [unfilteredProviders, pinnedProviderIds],
  );

  const pinnedProviders = useMemo(
    () =>
      unfilteredPinnedProviders.filter((provider) =>
        provider.displayName
          .toLowerCase()
          .includes(searchTerm?.toLowerCase() || ''),
      ),
    [unfilteredPinnedProviders, searchTerm],
  );

  const currentProviders = useMemo(() => {
    const tabToProviders: Partial<Record<Tab, ProviderWithImplementation[]>> = {
      All: allProviders,
      Automated: automatedProviders,
      Assisted: assistedProviders,
      Pinned: pinnedProviders,
      New: newProviders,
    };

    return tabToProviders[selectedTab] || [];
  }, [
    allProviders,
    assistedProviders,
    automatedProviders,
    pinnedProviders,
    selectedTab,
    newProviders,
  ]);

  return {
    isLoading,
    error,
    allProviders,
    unfilteredProviders,
    automatedProviders,
    assistedProviders,
    stagedOAuthCredentials,
    stagedConfigurations,
    updateIsPinned,
    addOAuthCredentials,
    pinnedProviders,
    unfilteredPinnedProviders,
    newProviders,
    updateEnabled,
    selectedProvider,
    clearSelectedProvider,
    handlePinnedProviderDrag,
    hasChanges,
    updatePrimaryImplementation,
    toggleProviderImplementation,
    setSelectedProviderId,
    shouldAllowUpdatesToImplementation,
    hasStagedConfiguration,
    currentProviders,
  };
};
