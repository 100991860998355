/* eslint-disable react/jsx-key */
import {
  Table,
  Flex,
  Tbody,
  Thead,
  Td,
  Th,
  Tr,
  Box,
  Text,
} from '@chakra-ui/react';
import { OrganizationMember } from '@finch-api/developer-dashboard-common';
import { useMemo, useState } from 'react';
import {
  useReactTable,
  createColumnHelper,
  getCoreRowModel,
  flexRender,
  getPaginationRowModel,
  type Row,
  type PaginationState,
  type HeaderGroup,
} from '@tanstack/react-table';
import { filterFns } from '../../../components/TableFilters';
import { EmptyStateMessage } from './EmptyStateMembersTable';
import {
  OrganizationMemberColumn,
  MemberStatusCell,
  MemberCell,
  MemberMfaCell,
  MemberRoleCell,
} from './MemberTableCell';
import { OptionsButton } from './OptionsButton';
import { Pagination } from '../Pagination';
import { useOrganization } from '../../../shared/hooks/use-organization';
import { ContentBox } from '../../../components/ContentBox';
import { COLORS } from '../../../constant';
import { Loading } from '../../../components/Loading/Loading';

const TableHeaderContent = ({
  headerGroups,
}: {
  headerGroups: HeaderGroup<OrganizationMemberColumn>[];
}) => {
  return (
    <>
      {headerGroups.map((headerGroup) => (
        <Tr key={headerGroup.id}>
          {headerGroup.headers.map((header) => (
            <Th
              key={header.id}
              width={header.column.columnDef.meta?.style?.width}
              p={header.column.columnDef.meta?.style?.padding}
              textTransform={'capitalize'}
            >
              <Flex
                alignItems="center"
                width={header.column.columnDef.meta?.style?.width}
              >
                <Text color={COLORS.GRAY.GRAY_600} fontWeight="medium">
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext(),
                  )}
                </Text>
              </Flex>
            </Th>
          ))}
        </Tr>
      ))}
    </>
  );
};

const TableBodyContent = ({
  rows,
}: {
  rows: Row<OrganizationMemberColumn>[];
}) => {
  return (
    <>
      {rows.map((row) => (
        <Tr key={row.id}>
          {row.getVisibleCells().map((cell) => (
            <Td key={cell.id} p={cell.column.columnDef.meta?.style?.padding}>
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </Td>
          ))}
        </Tr>
      ))}
    </>
  );
};

const transformToOrganizationMemberColumn = (
  organizationMembers: OrganizationMember[],
): OrganizationMemberColumn[] => {
  return organizationMembers.map((organizationMember) => {
    return {
      member: {
        email: organizationMember.member.email,
        id: organizationMember.member.id,
      },
      roles: organizationMember.roles,
      invitation: organizationMember.invitation,
      hasAccepted: organizationMember.invitation.has_accepted,
      mfaEnabled: organizationMember.mfa?.enabled ?? false,
    };
  });
};

export const MembersTable = () => {
  const { members } = useOrganization();

  const data = useMemo(() => {
    return transformToOrganizationMemberColumn(members.data || []);
  }, [members.data]);

  const columnHelper = createColumnHelper<OrganizationMemberColumn>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('member', {
        header: 'Member',
        cell: (props) => <MemberCell props={props} />,
        meta: { style: { width: '100%' } },
      }),
      columnHelper.accessor('roles', {
        header: 'Role',
        cell: (props) => <MemberRoleCell props={props} />,
        meta: { style: { width: '100px' } },
      }),
      columnHelper.accessor('invitation', {
        header: 'Status',
        cell: (props) => <MemberStatusCell props={props} />,
        meta: { style: { width: '100px' } },
      }),
      columnHelper.accessor('mfaEnabled', {
        header: 'MFA',
        cell: (props) => <MemberMfaCell props={props} />,
        meta: { style: { width: '100px' } },
      }),
      columnHelper.display({
        // This has no header because we want these to be static buttons.
        id: 'options',
        cell: ({ row }: { row: Row<OrganizationMemberColumn> }) => (
          <OptionsButton row={row} />
        ),
        meta: { style: { width: '10px', padding: '10px 20px' } },
      }),
    ],
    [],
  );

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  const {
    nextPage,
    previousPage,
    getHeaderGroups,
    getState,
    getRowModel,
    getCanPreviousPage,
    getCanNextPage,
    getFilteredRowModel,
  } = useReactTable({
    columns,
    data,
    autoResetPageIndex: false,
    state: { pagination },
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    filterFns,
  });

  const state = getState();
  const rows = getRowModel().rows;
  const shouldShowEmptyState = data.length === 0 && !members.isPending;

  return (
    <Box>
      <ContentBox overflow="scroll">
        {members.isPending ? (
          <Loading
            omitBorder
            message="Your organization members are being fetched"
          />
        ) : (
          <>
            <Table
              variant="simple"
              // Chakra doesn't seem to accept outlineStyle and outlineWidth, only
              // outline and outlineColor.
              overflow="scroll"
              title="Members table"
            >
              <Thead>
                <TableHeaderContent headerGroups={getHeaderGroups()} />
              </Thead>
              <Tbody>
                <TableBodyContent rows={rows} />
              </Tbody>
            </Table>
            {shouldShowEmptyState && <EmptyStateMessage />}
          </>
        )}
      </ContentBox>
      {!shouldShowEmptyState && (
        <Box paddingTop={2}>
          <Pagination
            previousPage={previousPage}
            canPreviousPage={getCanPreviousPage()}
            nextPage={nextPage}
            canNextPage={getCanNextPage()}
            pageIndex={state.pagination.pageIndex}
            pageSize={state.pagination.pageSize}
            totalSize={getFilteredRowModel().rows.length}
          />
        </Box>
      )}
    </Box>
  );
};
