import { COLORS } from '../../constant';
import { OrderedList, Text, ListItem } from '@chakra-ui/react';
import { ConnectionAccountDetail } from '../types';

import {
  ConnectionCard,
  ConnectionCardHeader,
  ConnectionCardBody,
  ConnectionCardRow,
} from './ConnectionCard';
import { AccountStatus } from '@finch-api/common/dist/external/dashboard/connection-status';

export const LastUsedTokensCard = ({
  connectionDetail,
}: {
  connectionDetail: ConnectionAccountDetail;
}) => {
  const opacity =
    connectionDetail.connectionStatus === AccountStatus.DISCONNECTED ? 0.5 : 1;

  return (
    <ConnectionCard>
      <ConnectionCardHeader>
        <Text
          fontFamily="Inter"
          fontStyle="normal"
          fontWeight="500"
          fontSize="14px"
          lineHeight="17px"
          color={COLORS.FINCH.BLACK}
        >
          Last Used Access Tokens
        </Text>
      </ConnectionCardHeader>
      <ConnectionCardBody spacing="16px">
        <ConnectionCardRow>
          <OrderedList
            styleType="decimal"
            spacing={3}
            maxH="200px"
            sx={{
              columnCount: [1, 2], // Number of columns for different screen sizes
              columnGap: '20px',
            }}
            opacity={opacity}
          >
            {connectionDetail.lastRedactedTokensUsed.map((token, index) => (
              <ListItem
                key={index}
                fontFamily="Inter"
                fontWeight={400}
                fontSize="14px"
                lineHeight="17px"
                color={COLORS.GRAY.GRAY_600}
                width="200px"
              >
                <Text
                  fontFamily="Inter"
                  fontWeight={400}
                  fontSize="14px"
                  lineHeight="17px"
                  color={COLORS.FINCH.BLACK}
                  width="200px"
                >
                  {token}
                </Text>
              </ListItem>
            ))}
          </OrderedList>
        </ConnectionCardRow>
      </ConnectionCardBody>
    </ConnectionCard>
  );
};
