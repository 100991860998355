import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Organization as APIOrganization } from '@finch-api/developer-dashboard-common';
import axios from 'axios';
import * as Sentry from '@sentry/react';

import { baseURL } from '../utils/baseUrls';

const OrganizationContext = createContext<OrganizationContextValue | undefined>(
  undefined,
);

type OrganizationContextValue = {
  organization?: APIOrganization;
  acceptTOS: () => Promise<void>;
  fetchOrganization: () => Promise<APIOrganization | undefined>;
  isFetchingOrg: boolean;
};
const getOrganization = async (token: string) => {
  try {
    const response = await axios.get<APIOrganization>(
      `${baseURL}/api/v1/organization`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return response.data;
  } catch (err) {
    console.log(err);
    Sentry.captureException(err);
    throw err;
  }
};

const acceptCurrentTermsOfService = async (token: string) => {
  try {
    await axios.post(
      `${baseURL}/api/v1/organization/tos`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return;
  } catch (err) {
    console.log(err);
    Sentry.captureException(err);
    throw err;
  }
};

export const Organization = ({ children }: { children: JSX.Element }) => {
  const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();

  const [organization, setOrganization] = useState<
    APIOrganization | undefined
  >();
  const [isFetchingOrg, setIsFetchingOrg] = useState(true);

  const fetchOrganization = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();
      const organization = await getOrganization(token);
      setOrganization(organization);
    } finally {
      setIsFetchingOrg(false);
    }
    return organization;
  }, [setOrganization, getAccessTokenSilently]);

  const acceptTOS = useCallback(async () => {
    const token = await getAccessTokenSilently();
    await acceptCurrentTermsOfService(token);
    await fetchOrganization();
    return;
  }, [fetchOrganization, getAccessTokenSilently]);

  useEffect(() => {
    if (isLoading || !isAuthenticated) return;

    // Auth'ed, get the org
    (async () => {
      await fetchOrganization();
    })();
  }, [isAuthenticated, isLoading, fetchOrganization]);

  return (
    <OrganizationContext.Provider
      value={{
        organization,
        fetchOrganization,
        acceptTOS,
        isFetchingOrg,
      }}
    >
      {children}
    </OrganizationContext.Provider>
  );
};

export const useOrganization = () => {
  const context = useContext(OrganizationContext);
  if (context === undefined) {
    throw new Error(
      'useOrganization must be used within a OrganizationContext.Provider',
    );
  }
  return context;
};
