import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import { COLORS } from '../../constant';
import { Spacer } from '../../shared/Spacer';
import {
  WebhookEndpoint,
  WebhookEvent,
} from '@finch-api/developer-dashboard-common/dist/api/webhook';
import { WebhookFormData, WebhookFormError } from '../types';
import { useEffect, useState } from 'react';
import { WebhookForm } from './WebhookForm';
import { validateFormData } from '../utils';
import { useUpdateWebhookEndpoint } from '../hooks';
import { useStatusToast } from '../../shared/StatusToast';

export const EditWebhookDrawer = ({
  applicationId,
  endpoint,
  isOpen,
  onClose,
  events,
  onWebhookUpdated,
}: {
  applicationId: string;
  endpoint: WebhookEndpoint;
  isOpen: boolean;
  onClose: () => void;
  events: WebhookEvent[];
  onWebhookUpdated: (endpoint: WebhookEndpoint) => void;
}) => {
  const toast = useStatusToast('bottom');
  const { loading, mutate } = useUpdateWebhookEndpoint();
  const [formError, setFormError] = useState<WebhookFormError | undefined>(
    undefined,
  );
  const [formData, setFormData] = useState<WebhookFormData>({
    endpointURL: endpoint.url,
    endpointEnabled: endpoint.enabled,
    events: endpoint.eventTypes,
  });

  useEffect(() => {
    setFormData({
      endpointURL: endpoint.url,
      endpointEnabled: endpoint.enabled,
      events: endpoint.eventTypes,
    });
  }, [endpoint]);

  const updateWebhook = async () => {
    const validationError = validateFormData(formData);
    setFormError(validationError ?? undefined);
    if (!validationError) {
      const { data, error } = await mutate(applicationId, endpoint.id, {
        url: formData.endpointURL,
        description: '',
        eventTypes: formData.events,
        enabled: formData.endpointEnabled,
      });

      if (error || !data) {
        toast({
          wasSuccessful: false,
          message: 'Failed to update the webhook endpoint',
        });
      } else {
        toast({
          wasSuccessful: true,
          message: 'Successfully updated the webhook endpoint',
        });
        onWebhookUpdated(data.endpoint);
        onClose();
      }
    }
  };

  return (
    <Drawer isOpen={isOpen} onClose={onClose} size="lg">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader
          borderBottomWidth="1px"
          fontFamily="Inter"
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Text fontSize="18px">Edit Webhook</Text>
          <Box display="flex" flexDirection={'row'}>
            <Button
              fontWeight={'medium'}
              size="md"
              variant="outline"
              mr={3}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              variant={'primaryPurple'}
              size="md"
              isLoading={loading}
              onClick={updateWebhook}
            >
              Save
            </Button>
          </Box>
        </DrawerHeader>

        <DrawerBody backgroundColor={COLORS.GRAY.GRAY_100}>
          <Spacer p="2" />
          <Stack>
            <WebhookForm
              initFormData={formData}
              events={events}
              onFormUpdated={setFormData}
              error={formError}
            />
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
