import { Button, Center, Text, VStack, Link, Box } from '@chakra-ui/react';
import { Spacer } from '../../shared/Spacer';
import { COLORS } from '../../constant';
import { Caption } from '../../shared/Typography';
import { WebhooksIcon } from './WebhookIcon';
import { FiPlus } from 'react-icons/fi';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { docLinks } from '../../shared/links';
import { ContentBox } from '../../components/ContentBox';

export const WebhooksEmpty = ({ onClick }: { onClick: () => void }) => {
  return (
    <Box>
      <ContentBox>
        <Center paddingY="64px" paddingX="16px">
          <Caption>
            <Center>
              <WebhooksIcon
                backgroundColor={'#EFF1FF'}
                iconColor={COLORS.FINCH.PURPLE}
              />
            </Center>
            <Spacer p="3" />
            <VStack>
              <Text
                fontSize="16px"
                fontWeight="600"
                lineHeight="24px"
                align="center"
                fontFamily="Inter"
              >
                {"It's time to create your first webhook!"}
              </Text>
              <Spacer p="1" />
              <Button
                leftIcon={<FiPlus />}
                variant="outline"
                colorScheme="white"
                borderColor={COLORS.GRAY.GRAY_400}
                p={[2, 5]}
                _hover={{ bg: 'rgb(244,244,244)' }}
                fontWeight="medium"
                size="md"
                onClick={onClick}
              >
                Create Webhook
              </Button>
            </VStack>
          </Caption>
        </Center>
      </ContentBox>
      <Spacer p="3" />
      <Center>
        <AiOutlineQuestionCircle />
        <Spacer p="1" />
        <Text
          fontSize="14px"
          lineHeight="24px"
          align="center"
          fontFamily="Inter"
          fontWeight="400"
        >
          Need a little help getting started? Learn all about webhooks in the
          <Link href={docLinks.webhooks} color={COLORS.FINCH.PURPLE} isExternal>
            {' Finch Docs.'}
          </Link>
        </Text>
      </Center>
    </Box>
  );
};
