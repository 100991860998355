import { memo, useEffect, useMemo, useState } from 'react';
import {
  Table,
  TableContainer,
  Tbody,
  Box,
  useColorModeValue,
  useColorMode,
  Stack,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import {
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  type PaginationState,
  useReactTable,
  type RowPinningState,
  type ColumnPinningState,
  type ColumnFiltersState,
  type HeaderGroup,
  type Row,
} from '@tanstack/react-table';
import sortBy from 'lodash/sortBy';
import isEmpty from 'lodash/isEmpty';

import { COLORS, SHADOWS } from '../../constant';
import {
  filterFns,
  SelectFilterInput,
  TableFilters,
  useFilters,
} from '../../components/TableFilters';
import { TableHeaderContent } from '../../components/TableHeaderContent';
import {
  AuthenticationMethod,
  FieldSupportCell,
  FieldSupportTableFilterId,
  FilterOption,
  Group,
  ProviderSupport,
  Subgroup,
} from '../types';
import {
  authorizationType,
  fieldSupportFilterList,
  footnotes,
} from '../constants';
import {
  AuthorizationTypeCell,
  RowLabelCell,
  CoverageCell,
  FieldAvailabilityCell,
  NoSupportCell,
  NotAvailableCell,
  ProviderHeaderCell,
  FinchHeaderCell,
  SupportCell,
  StatusCell,
} from './TableCells';
import { FieldSupportRow, PinnedRow } from './TableRows';
import { useFieldSupportSearchParamsSync } from '../hooks/useFieldSupportSearchParamsSync';
import { useColours } from '../hooks/useColours';
import { useColumnFilters } from '../hooks/useColumnFilters';
import { ExternalLink } from '../../shared/ExternalLink';
import { useSelectedGroup } from '../hooks/useSelectedGroup';
import { useSelectedSubgroup } from '../hooks/useSelectedSubgroup';
import {
  createContextualFilter,
  filterOptionCategory,
  toRowData,
} from '../utils';

const Style = ({ children }: { children: JSX.Element }) => {
  const theme = useColours();
  const Styles = styled.div`
    table {
      border-spacing: 0;

      th {
        padding: 8px;
        border: 0px;
        border-top: 1px solid ${theme.border};
        border-bottom: 1px solid ${theme.border};

        border-right: 1px solid ${theme.border};

        :last-child {
          border-right: 0px;
        }
      }

      tr {
        border-bottom: 1px solid ${theme.border};

        :last-child {
          border-bottom: 0px;
        }
      }

      td {
        padding: 10px 41px;
        border: 0px;
        border-bottom: 1px solid ${theme.border};
        border-right: 1px solid ${theme.border};

        :last-child {
          border-right: 0px;
        }
      }
    }
  `;

  return <Styles>{children}</Styles>;
};

const BoxedTable = memo(
  ({
    headers,
    pinned,
    center,
  }: {
    headers: HeaderGroup<FieldSupportCell>[];
    pinned: Row<FieldSupportCell>[];
    center: Row<FieldSupportCell>[];
    // NOTE: used for invalidating memo
    filters: ColumnFiltersState;
    group: Group;
    subgroup: Subgroup;
  }) => {
    const theme = useColours();
    const scrollbarColor = [
      useColorModeValue('auto', '#6b6b6b'),
      useColorModeValue('auto', '#2c2c2c'),
    ];

    return (
      <Box
        backgroundColor={theme.background}
        display="flex"
        flexDirection="column"
        width="100%"
        maxWidth="100vw"
        height="100%"
        maxHeight="75vh"
        position="relative"
        overflowY="scroll"
        style={{
          scrollbarColor: scrollbarColor.join(' '),
        }}
      >
        <TableContainer display="table" position="relative">
          <Style>
            <Table
              style={{ borderCollapse: 'separate' }}
              borderTop={`1px solid ${theme.border}`}
              variant="simple"
            >
              <TableHeaderContent sticky headerGroups={headers} />
              <Tbody>
                {pinned.map((row) => (
                  <PinnedRow key={row.id} row={row} />
                ))}
                {center.map((row, index) => (
                  <FieldSupportRow key={index} row={row} />
                ))}
              </Tbody>
            </Table>
          </Style>
        </TableContainer>
      </Box>
    );
  },
  (oldProps, newProps) => {
    const someChanged = oldProps.filters.some((filter) => {
      const newFilter = newProps.filters.find((x) => x.id === filter.id);

      return (
        newFilter &&
        (newFilter.value as string[]).length !==
          (filter.value as string[]).length
      );
    });

    return (
      oldProps.center.length === newProps.center.length &&
      oldProps.headers.length === newProps.headers.length &&
      oldProps.pinned.length === newProps.pinned.length &&
      oldProps.filters.length === newProps.filters.length &&
      oldProps.group === newProps.group &&
      oldProps.subgroup === newProps.subgroup &&
      !someChanged
    );
  },
);

BoxedTable.displayName = 'BoxedTable';

export const FieldSupportTable = ({
  implementations,
  coverage = false,
  availability = false,
}: {
  implementations: ProviderSupport[];
  coverage?: boolean;
  availability?: boolean;
  filters?: ColumnFiltersState;
}) => {
  const {
    columnFilters,
    setColumnFilters,
    selectedFilters,
    setSelectedFilters,
  } = useColumnFilters();
  const { selectedGroup } = useSelectedGroup();
  const { selectedSubgroup } = useSelectedSubgroup();

  useFieldSupportSearchParamsSync({
    columnFilters,
    selectedGroup,
    selectedSubgroup,
  });

  const columnData = useMemo(
    () => sortBy(implementations, (provider) => provider.name.toLowerCase()),
    [implementations],
  );
  const rowData = useMemo(
    toRowData(implementations, coverage, availability, false),
    [implementations],
  );
  const [providerFilterInputOptions, authorizationTypeFilterInputOptions] =
    useMemo(() => {
      const [providers, methods] = implementations.reduce<
        [Record<string, FilterOption>, Record<string, FilterOption>]
      >(
        (partitions, implementation) => {
          const [providers, methods] = partitions;

          providers[implementation.id] = {
            id: implementation.id,
            label: implementation.name,
          };
          methods[implementation.type] = {
            id: implementation.type,
            label: authorizationType[implementation.type],
          };

          return partitions;
        },
        [Object.create(null), Object.create(null)],
      );

      return [
        Object.values(providers).sort((a, b) => a.label.localeCompare(b.label)),
        Object.values(methods).sort((a, b) => a.label.localeCompare(b.label)),
      ];
    }, [implementations]);
  const [endpointFilterInputOptions, fieldFilterInputOptions] = useMemo(() => {
    const selectedScopeFilter = selectedFilters?.includes('endpoint');
    const selectedScopes = (columnFilters.find(({ id }) => id === 'endpoint')
      ?.value || []) as string[];
    const [endpoints, fields] = rowData.reduce<
      [Record<string, FilterOption>, Record<string, FilterOption>]
    >(
      (partitions, row) => {
        const [endpoints, fields] = partitions;

        switch (row.kind) {
          case 'subheading':
            endpoints[row.endpoint] = {
              id: row.endpoint,
              label: row.group,
            };

            break;

          case 'support':
            if (
              selectedScopeFilter &&
              !selectedScopes?.includes(row.endpoint)
            ) {
              break;
            }
            fields[filterOptionCategory(row.field)] = {
              id: filterOptionCategory(row.field),
              label: filterOptionCategory(row.field),
            };

            break;
        }

        return partitions;
      },
      [Object.create(null), Object.create(null)],
    );

    return [
      Object.values(endpoints).sort((a, b) => a.label.localeCompare(b.label)),
      Object.values(fields).sort((a, b) => a.label.localeCompare(b.label)),
    ];
  }, [rowData, columnFilters, selectedFilters]);

  const filterProviders = (provider: ProviderSupport) => {
    const selected = columnFilters.find(({ id }) => id === 'provider')
      ?.value as string[];

    if (!selected || selected.length === 0) {
      return selectedGroup === 'deductions'
        ? !isEmpty(provider.supportedFields)
        : true;
    }

    return selected?.includes(provider.id);
  };
  const filterAuthorizationTypes = (provider: ProviderSupport) => {
    const authTypes = columnFilters.find(
      ({ id }) => id === 'authorization_type',
    )?.value as string[];

    if (!authTypes || authTypes.length === 0) {
      return true;
    }

    return authTypes?.includes(provider.type);
  };
  const filterLabels = useMemo(() => {
    let field = 'Field Name';
    let endpoint = 'Scope';

    if (selectedGroup === 'deductions') {
      if (selectedSubgroup === 'operations') {
        field = 'Benefit Name';
        endpoint = 'Operation Type';
      } else if (selectedSubgroup === 'features') {
        field = 'Benefit Feature';
        endpoint = 'Benefit Name';
      }
    }

    return {
      field,
      endpoint,
      provider: 'Provider',
      authorizationType: 'Authentication Type',
    } as const;
  }, [selectedGroup, selectedSubgroup]);
  const theme = useColours();
  const { colorMode } = useColorMode();
  const color = useColorModeValue(undefined, '#9f9f9f');
  const border = useColorModeValue(COLORS.GRAY.GRAY_400, '#ffffff1a');
  const background = useColorModeValue(COLORS.WHITE, '#1a1a1a');
  const hovered = useColorModeValue(COLORS.GRAY.GRAY_200, '#212121');
  const shadows = useColorModeValue(
    SHADOWS.PAGE,
    '0px 4px 8px 0px rgba(22, 22, 22, 0.06), 0px 0px 1px 0px rgba(22, 22, 22, 0.12)',
  );
  const columnHelper = createColumnHelper<FieldSupportCell>();
  const providerColumns = useMemo(
    () => columnData.filter(filterProviders).filter(filterAuthorizationTypes),
    [columnData, columnFilters],
  );
  const rowFilter = createContextualFilter(
    selectedFilters?.includes('endpoint')
      ? (columnFilters.find(({ id }) => id === 'endpoint')?.value as string[])
      : endpointFilterInputOptions.map(({ id }) => id),
  );

  const uri = new URL(window.location.href);
  const fromDocs =
    uri.searchParams.has('iframe') && uri.searchParams.get('iframe') === 'docs';

  if (fromDocs) {
    uri.searchParams.delete('iframe');
  }

  const columns = [
    columnHelper.accessor('field', {
      header: () => <FinchHeaderCell fromDocs={fromDocs} />,
      enableSorting: false,
      filterFn: rowFilter,
      meta: {
        filterInput: SelectFilterInput,
        filterInputOptions: fieldFilterInputOptions,
        filterLabel: filterLabels.field,
        style: {
          backgroundColor: theme.background,
          color: theme.color,
        },
      },
      cell: (props) => {
        const content = props.getValue() as string;

        // NOTE: subheadings are manually handled in the table body
        if (props.row.original.kind === 'subheading') {
          return null;
        }

        return (
          <RowLabelCell kind={props.row.original.kind}>{content}</RowLabelCell>
        );
      },
    }),
    columnHelper.accessor('provider', {
      header: 'Provider',
      enableHiding: true,
      filterFn: () => true,
      meta: {
        filterInput: SelectFilterInput,
        filterInputOptions: providerFilterInputOptions,
        filterLabel: filterLabels.provider,
      },
    }),
    columnHelper.accessor('authorization_type', {
      header: 'Authentication Type',
      enableHiding: true,
      filterFn: () => true,
      meta: {
        filterInput: SelectFilterInput,
        filterInputOptions: authorizationTypeFilterInputOptions,
        filterLabel: filterLabels.authorizationType,
      },
    }),
    columnHelper.accessor('endpoint', {
      header: 'Scope',
      enableHiding: true,
      filterFn: rowFilter,
      meta: {
        filterInput: SelectFilterInput,
        filterInputOptions: endpointFilterInputOptions,
        filterLabel: filterLabels.endpoint,
      },
    }),
    ...providerColumns.map((provider) => {
      return columnHelper.accessor([provider.id, provider.type].join('_'), {
        header: () => (
          <ProviderHeaderCell name={provider.name} src={provider.icon} />
        ),
        enableSorting: false,
        meta: {
          style: {
            minWidth: '160px',
            maxWidth: '160px',
            backgroundColor: theme.background,
            color: theme.color,
          },
        },
        cell: (props) => {
          switch (props.row.original.kind) {
            case 'authorization_type':
              return (
                <AuthorizationTypeCell
                  implementation={
                    props.row.original[props.column.id] as AuthenticationMethod
                  }
                />
              );

            case 'status':
              return (
                <StatusCell
                  beta={props.row.original[props.column.id] as boolean}
                />
              );

            case 'field_availability':
              return (
                <FieldAvailabilityCell
                  supported={
                    props.row.original[props.column.id] as [number, number]
                  }
                />
              );

            case 'coverage':
              return (
                <CoverageCell
                  percentage={props.row.original[props.column.id] as number}
                />
              );

            case 'support':
              if (props.getValue()) {
                const field = [
                  provider.id,
                  provider.type,
                  props.row.original.endpoint,
                  props.row.original.field,
                ].join('.');

                if (field in footnotes) {
                  return footnotes[field]!();
                }

                const section = [
                  provider.id,
                  provider.type,
                  props.row.original.endpoint,
                  '*', // NOTE: convention to specify _all_ fields in a scope
                ].join('.');

                if (section in footnotes) {
                  return footnotes[section]!();
                }

                return <SupportCell />;
              }

              return <NoSupportCell />;

            // NOTE: rendered elsewhere manually, as we need control over the
            // specific table row's styling and content
            case 'subheading':
              return null;

            default:
              return <NotAvailableCell />;
          }
        },
      });
    }),
  ];

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: rowData.length,
  });

  useEffect(
    () => setPagination((prev) => ({ ...prev, pageSize: rowData.length })),
    [rowData],
  );

  const [columnVisibility, setColumnVisibility] = useState<
    Record<keyof FieldSupportCell, boolean>
  >({
    authorization_type: false,
    endpoint: false,
    provider: false,
  });

  const [rowPinning, setRowPinning] = useState<RowPinningState>({ top: ['0'] });
  const [columnPinning, setColumnPinning] = useState<ColumnPinningState>({
    left: ['field'], // that was out of
  });

  const table = useReactTable({
    columns,
    data: rowData,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onRowPinningChange: setRowPinning,
    onColumnPinningChange: setColumnPinning,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    state: {
      pagination,
      columnFilters,
      columnPinning,
      rowPinning,
      columnVisibility,
    },
    filterFns,
  });

  useEffect(() => {
    table.getRow('0', true)?.pin('top');
    table.getColumn('field')?.pin('left');
  }, [implementations]);

  const {
    filterConfigs,
    getFilterValue,
    setFilterValue,
    addFilter,
    deleteFilter,
    toggleOpen,
    activeFilterId,
  } = useFilters<FieldSupportCell, FieldSupportTableFilterId>({
    filterList: [...fieldSupportFilterList],
    getColumn: table.getColumn.bind(table),
    selectedFilters,
    setSelectedFilters,
  });

  return (
    <>
      <TableFilters
        hover={colorMode === 'dark' ? { bg: '#1e1e1e' } : undefined}
        borderColor={border}
        color={color}
        menu={{ _hover: { bg: hovered }, backgroundColor: background }}
        shadows={shadows}
        setFilterValue={setFilterValue}
        getFilterValue={getFilterValue}
        filterConfigs={filterConfigs}
        addFilter={addFilter}
        deleteFilter={deleteFilter}
        selectedFilters={selectedFilters}
        toggleOpen={toggleOpen}
        activeFilterId={activeFilterId}
      />
      <Stack gap="48px">
        <BoxedTable
          group={selectedGroup}
          subgroup={selectedSubgroup}
          filters={columnFilters}
          headers={table.getHeaderGroups()}
          pinned={table.getTopRows()}
          center={table.getCenterRows()}
        />
        {fromDocs && (
          <Box textAlign="right">
            <ExternalLink to={uri.toString()}>
              Open matrix in a new window
            </ExternalLink>
          </Box>
        )}
      </Stack>
    </>
  );
};
