import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Icon,
  Text,
  Stack,
  ListItem,
  OrderedList,
  Link,
} from '@chakra-ui/react';
import { COLORS } from '../../constant';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import { Spacer } from '../../shared/Spacer';
import {
  WebhookEndpointWithSecret,
  WebhookEvent,
} from '@finch-api/developer-dashboard-common/dist/api/webhook';
import { useState } from 'react';
import { WebhookFormData, WebhookFormError } from '../types';
import { WebhookForm } from './WebhookForm';
import { validateFormData } from '../utils';
import { useCreateWebhookEndpoint } from '../hooks';
import { useStatusToast } from '../../shared/StatusToast';
import { ContentBox } from '../../components/ContentBox';
import { docLinks } from '../../shared/links';

const CreateWebhookInstruction = () => (
  <ContentBox>
    <Box padding="16px" gap="16px" display={'flex'} flexDirection="column">
      <Box color={COLORS.FINCH.PURPLE} fontSize="14px">
        <Stack direction="row" align={'center'}>
          <Icon as={BsFillInfoCircleFill} spacing="2px" />
          <Text> Good to Know</Text>
        </Stack>
      </Box>
      <Box color={COLORS.FINCH.BLACK} fontSize="14px">
        <Stack direction="column">
          <Text>
            Create an endpoint to receive events when your connections and
            directories are changed. To implement webhooks in a secure and
            reliable way, follow the steps below:
          </Text>
          <Spacer />
          <Box padding="4px">
            <OrderedList>
              <ListItem>Create an endpoint with a subset of events</ListItem>
              <ListItem>
                Read our{' '}
                <Link
                  target="_blank"
                  color="primary.c600"
                  fontWeight={'medium'}
                  href={docLinks.webhooksBestPractices}
                >
                  recommended best practices
                </Link>{' '}
                on endpoint servers
              </ListItem>
              <ListItem>
                Send a test webhook event to check your server status
              </ListItem>
            </OrderedList>
          </Box>
        </Stack>
      </Box>
    </Box>
  </ContentBox>
);

export const CreateWebhookDrawer = ({
  applicationId,
  isOpen,
  onClose,
  events,
  onWebhookCreated,
}: {
  applicationId: string;
  isOpen: boolean;
  onClose: () => void;
  events: WebhookEvent[];
  onWebhookCreated: (endpointWithSecret: WebhookEndpointWithSecret) => void;
}) => {
  const emptyFormData: WebhookFormData = {
    endpointURL: '',
    endpointEnabled: true,
    events: [],
  };

  const toast = useStatusToast('bottom');
  const { loading, mutate } = useCreateWebhookEndpoint();
  const [formError, setFormError] = useState<WebhookFormError | undefined>(
    undefined,
  );
  const [formData, setFormData] = useState<WebhookFormData>(emptyFormData);

  const createWebhook = async () => {
    const validationError = validateFormData(formData);
    setFormError(validationError ?? undefined);
    if (!validationError) {
      const { data, error } = await mutate(applicationId, {
        url: formData.endpointURL,
        description: '',
        eventTypes: formData.events,
        enabled: formData.endpointEnabled,
      });

      if (error || !data) {
        toast({
          message: 'Failed to create a new webhook endpoint',
          wasSuccessful: false,
        });
      } else {
        toast({
          message: 'Successfully created a new webhook endpoint',
          wasSuccessful: true,
        });
        onWebhookCreated(data.endpoint);
        setFormData(emptyFormData);
        onClose();
      }
    }
  };

  return (
    <Drawer isOpen={isOpen} onClose={onClose} size="xl" variant={'secondary'}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader
          borderBottomWidth="1px"
          fontFamily="Inter"
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Text fontSize="18px">Create New Webhook Endpoint</Text>
          <Box display="flex" flexDirection={'row'}>
            <Button
              fontWeight={'medium'}
              size="md"
              variant="outline"
              mr={3}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              variant={'primaryPurple'}
              size="md"
              onClick={createWebhook}
              isLoading={loading}
            >
              Create Webhook
            </Button>
          </Box>
        </DrawerHeader>

        <DrawerBody backgroundColor={COLORS.GRAY.GRAY_100}>
          <Spacer p="2" />
          <Box
            display={'flex'}
            flexDirection={{ md: 'row', sm: 'column-reverse' }}
            gap={'24px'}
          >
            <Box flexGrow={1} minWidth={'400px'}>
              <WebhookForm
                initFormData={formData}
                events={events}
                onFormUpdated={setFormData}
                error={formError}
              />
            </Box>
            <Box flexGrow={1} maxWidth={{ md: '476px', sm: '100%' }}>
              <CreateWebhookInstruction />
            </Box>
          </Box>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
