import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Button,
  Text,
} from '@chakra-ui/react';
import { COLORS } from '../../constant';
import { useRotateEndpointSecret } from '../hooks';
import { useStatusToast } from '../../shared/StatusToast';

export const RotateSecretConfirmationModal = ({
  isOpen,
  onClose,
  applicationId,
  endpointId,
  endpointURL,
  onWebhookSecretRotated,
}: {
  isOpen: boolean;
  onClose: () => void;
  applicationId: string;
  endpointId: string;
  endpointURL: string;
  onWebhookSecretRotated: (secret: string) => void;
}) => {
  const toast = useStatusToast('bottom');
  const { loading, mutate } = useRotateEndpointSecret();

  const warningText = () => (
    <>
      After rotating the secret, you will have 24 hours to update the secret for{' '}
      <Text as="span" fontWeight="bold">
        {endpointURL}
      </Text>{' '}
      in your application before the existing secret expires.
    </>
  );

  const rotateSecret = async () => {
    const { data, error } = await mutate(applicationId, endpointId);

    if (error || !data) {
      toast({
        message: `Failed to rotate the secret for ${endpointURL}`,
        wasSuccessful: false,
      });
    } else {
      toast({
        message: `Successfully rotated the secret for ${endpointURL}`,
        wasSuccessful: true,
      });
      onWebhookSecretRotated(data.secret);
      onClose();
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            fontFamily="Inter"
            fontSize="18px"
            fontWeight="semibold"
            color={COLORS.FINCH.BLACK}
            alignSelf="center"
          >
            Rotate Secret
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontSize="14px" color={COLORS.FINCH.BLACK}>
              {warningText()}
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button
              w="full"
              onClick={rotateSecret}
              isLoading={loading}
              variant="primaryPurple"
            >
              Rotate Secret
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
