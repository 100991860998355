import { Button, type ButtonProps } from '@chakra-ui/react';

export const HeadingButton = ({
  children,
  ...props
}: ButtonProps & { children: React.ReactNode }) => {
  return (
    <Button
      p="2"
      fontWeight="400"
      h="6"
      fontSize="13px"
      sx={{ '.chakra-button__icon': { mr: '1' } }}
      {...props}
    >
      {children}
    </Button>
  );
};
