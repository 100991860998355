import {
  DateRange,
  RelativeDateRange,
  RelativeDateUnit,
} from '../../components/TableFilters/types';

export const dateRangeOptions: { id: DateRange; label: string }[] = [
  {
    id: 'today',
    label: 'Today',
  },
  {
    id: 'last_7_days',
    label: 'Last 7 Days',
  },
  {
    id: 'last_30_days',
    label: 'Last 30 Days',
  },
  {
    id: 'last_90_days',
    label: 'Last 90 Days',
  },
  {
    id: 'last_12_months',
    label: 'Last 12 Months',
  },
  {
    id: 'custom_range',
    label: 'Custom Range',
  },
];

export const relativeDateRangeOptions: {
  id: [RelativeDateRange, RelativeDateUnit];
  label: string;
}[] = [
  {
    id: ['more', 'hours'],
    label: 'More than',
  },
  {
    id: ['more', 'days'],
    label: 'More than',
  },
  {
    id: ['more', 'weeks'],
    label: 'More than',
  },
  {
    id: ['more', 'months'],
    label: 'More than',
  },
  {
    id: ['less', 'hours'],
    label: 'Less than',
  },
  {
    id: ['less', 'days'],
    label: 'Less than',
  },
  {
    id: ['less', 'weeks'],
    label: 'Less than',
  },
  {
    id: ['less', 'months'],
    label: 'Less than',
  },
];
