import { HTMLInputTypeAttribute, ReactNode } from 'react';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
} from '@chakra-ui/react';
import { type Options, Select } from 'chakra-react-select';
import {
  type RegisterOptions,
  type Path,
  type UseFormRegister,
  type Control,
  useController,
} from 'react-hook-form';
import { COLORS, Z_INDEX } from '../constant';

type SelectOption = {
  value: string;
  label: ReactNode;
};

export type FormSelectProps<FormData extends Record<string, unknown>> = {
  id: Path<FormData>;
  label?: string;
  register?: UseFormRegister<FormData>;
  error?: string;
  placeholder?: string;
  helpText?: string;
  name?: string;
  size?: 'sm' | 'md' | 'lg';
  portal?: boolean;
  rightElement?: React.ReactNode;
  inputRef?: React.RefObject<HTMLInputElement>;
  readOnly?: boolean;
  control: Control<FormData>;
  type?: HTMLInputTypeAttribute;
  registerOptions?: RegisterOptions;
  options: Options<SelectOption>;
  isLoading?: boolean;
};

export const FormSelect = <FormData extends Record<string, unknown>>({
  id,
  label,
  helpText,
  options,
  error,
  control,
  placeholder,
  isLoading,
  size = 'md',
  portal = false,
}: FormSelectProps<FormData>) => {
  const {
    field: { onChange, value, ...restOfFields },
  } = useController({
    name: id,
    control,
  });

  const fontSize = size === 'sm' ? 'xs' : 'sm';

  return (
    <FormControl isInvalid={!!error}>
      {label && (
        <FormLabel fontSize={fontSize} htmlFor={id}>
          {label}
        </FormLabel>
      )}
      <Select
        size={size}
        options={options}
        placeholder={placeholder}
        isLoading={isLoading}
        isClearable
        menuPortalTarget={portal ? document.body : undefined}
        chakraStyles={{
          container: (baseStyles) => ({ ...baseStyles, width: '100%' }),
          menuList: (baseStyles) => ({
            ...baseStyles,
            maxHeight: '160px',
          }),
        }}
        styles={{
          menuList: (baseStyles) => ({ ...baseStyles }),
          menuPortal: (baseStyles) => ({
            ...baseStyles,
            zIndex: Z_INDEX.MENU,
          }),
        }}
        value={options.find((option) => option.value === value)}
        onChange={(option) => onChange(option?.value)}
        {...restOfFields}
      />
      <FormHelperText fontSize={fontSize} color={COLORS.GRAY.GRAY_600}>
        {helpText}
      </FormHelperText>
      <FormErrorMessage fontSize={size === 'sm' ? 'xs' : 'sm'}>
        {error}
      </FormErrorMessage>
    </FormControl>
  );
};
