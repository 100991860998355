import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { useToast } from '@chakra-ui/react';
import { useAuth0 } from '@auth0/auth0-react';
import { useParams } from 'react-router-dom';
import {
  NewSession,
  ReauthSession,
} from '../components/ConnectSession/ModalNewConnectSession';
import { baseURL } from '../../utils/baseUrls';
import { useApplication } from '../../applications/hooks';
import uniqBy from 'lodash/uniqBy';
import startCase from 'lodash/startCase';
import { PRODUCT_LABEL_MAP } from '../../constant/scopes';
import { useSandboxStore } from '../state/sandboxStore';

export type Scope = { key: string; label: string; selected: boolean };

const mapScopes = (formScopes: Scope[]) => {
  return formScopes.filter((scope) => scope.selected).map(({ key }) => key);
};

export type ConnectSession = {
  clientId: string;
  clientName: string | null;
  connectionId: string | null;
  createdAt: Date;
  expiresAt: Date;
  externalCompanyEmail: string | null;
  externalCompanyId: string | null;
  externalCompanyName: string | null;
  id: string;
  implementationId: string | null;
  providerId: string | null;
  redirectUri: string;
  sandbox: string | null;
  scopes: string[];
  showAssisted: boolean;
  tokenId: string | null;
  updatedAt: Date;
  url: string;
};

export type CreateConnectSessionResponse = {
  sessionId: string;
  connectUrl: string;
};

export type CreateConnectSessionError = {
  message: string;
  extra?: { context?: { connectionId?: string } };
};

export const useConnectSession = () => {
  const toast = useToast();
  const { applicationId, connectionId } = useParams<{
    applicationId: string;
    connectionId: string;
  }>();
  const { getAccessTokenSilently } = useAuth0();
  const { application } = useApplication(applicationId);
  const selectedSandbox = useSandboxStore((store) => store.selectedSandbox);

  const scopes = uniqBy(
    application?.scopes
      .filter(({ name }) => name && PRODUCT_LABEL_MAP.get(name))
      .map(({ name }) => ({
        key: `employer:${name}`,
        selected: true,
        label: PRODUCT_LABEL_MAP.get(name) || startCase(name),
      })) || [],
    'key',
  );

  const list = useQuery({
    queryKey: ['connect-sessions', applicationId],
    queryFn: async () => {
      const uri = new URL(
        `/api/v1/applications/${applicationId}/connection-accounts/${connectionId}/connect-sessions`,
        baseURL,
      );
      return axios
        .get<{ sessions: ConnectSession[] }>(uri.toString(), {
          headers: {
            Authorization: `Bearer ${await getAccessTokenSilently()}`,
          },
        })
        .then((res) => res.data.sessions);
    },
    initialData: [],
    enabled: !!applicationId && !!connectionId,
  });

  const create = useMutation<
    CreateConnectSessionResponse,
    AxiosError<CreateConnectSessionError>,
    NewSession | ReauthSession
  >({
    mutationFn: async (session: NewSession | ReauthSession) => {
      const uri = new URL('/api/v1/connect-session', baseURL);

      return axios
        .post(
          uri.toString(),
          {
            ...session,
            scopes: mapScopes(scopes),
            clientId: applicationId,
            sandbox: application?.isSandbox() ? selectedSandbox : undefined,
          },
          {
            headers: {
              Authorization: `Bearer ${await getAccessTokenSilently()}`,
            },
          },
        )
        .then((res) => res.data);
    },
    onSuccess: () => {
      toast({
        title: 'Finch Connect URL created successfully',
        status: 'success',
        isClosable: true,
      });
      list.refetch();
    },
  });

  return { create, list };
};
