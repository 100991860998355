import { useMemo, useRef } from 'react';
import {
  Badge,
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  FormControl,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  SimpleGrid,
  Spinner,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { FiInfo } from 'react-icons/fi';

import {
  ApplicationStatus,
  Scope,
} from '@finch-api/developer-dashboard-common';

import { Spacer } from '../../shared/Spacer';

import { SubSectionHeading, PageHeading } from '../../shared/Typography';
import { RedirectURIsForm } from '../components/RedirectURIsForm';
import { RedirectURIs } from '../components/RedirectURIsContext';
import { ClipboardButton } from '../../components/ClipboardButton';
import { ClientSecretField } from '../components/ClientSecretField';
import { finchEmails } from '../../shared/links';
import { ApplicationStatusBadge } from '../components/ApplicationStatusBadge';
import { ContentBox } from '../../components/ContentBox';
import { COLORS } from '../../constant';
import { useApplication } from '../../applications/hooks';
import { NotFoundPage } from '../../NotFoundPage';
import { SandboxAlert } from '../../connections/components/SandboxAlert';
import { PageContainer } from '../../components/PageContainer';
import { PRODUCT_LABEL_MAP } from '../../constant/scopes';
import { SftpCredentialsForm } from '../components/SftpForm';
import { useUserRole } from '../../auth/use-user-role';
import { useFlag } from '@unleash/proxy-client-react';
import { FeatureFlag } from '../../constant/feature-flags';
import { Banner } from '../../components/Banner/Banner';
import warningInfoIcon from '../../assets/images/icons/warning_info.svg';
import { useSftpCredentials } from '../components/SftpForm/use-sftp-credentials';
import { PermissionRequired } from '../../components/PermissionRequired';
import { PERMISSION } from '../../constant/roles-and-permissions';
import { usePermissions } from '../../hooks/use-permissions';

const ProductCheckBoxesForm = ({ scopes }: { scopes: Scope[] }) => {
  const scopeCheckboxesToRender = useMemo(() => {
    const result: JSX.Element[] = [];

    for (const scope of scopes) {
      const productName = PRODUCT_LABEL_MAP.get(scope.name);
      if (!productName) continue;

      result.push(
        <Checkbox key={scope.name} isDisabled isChecked>
          <Text fontSize="14px">
            {productName}{' '}
            {scope.deprecated && <Badge fontSize="0.5em">Deprecated</Badge>}
          </Text>
        </Checkbox>,
      );
    }

    return result;
  }, [scopes]);

  return (
    <FormControl>
      <Flex alignItems="center">
        <SubSectionHeading>What Products are enabled?</SubSectionHeading>
        <Spacer p="1" />
        <Tooltip
          label={`These are set by Finch. Please contact ${finchEmails.developers} to change them.`}
          hasArrow
          placement="bottom-start"
        >
          <span>
            <FiInfo />
          </span>
        </Tooltip>
      </Flex>
      <Spacer p="2" />
      <SimpleGrid columns={[2, null, 3]} spacing="10px">
        {scopeCheckboxesToRender}
      </SimpleGrid>
    </FormControl>
  );
};

const CopiableTextForms = ({
  applicationName,
}: {
  applicationName: string;
}) => {
  const { applicationId } = useParams<{ applicationId: string }>();
  if (!applicationId) throw new Error('no application id in url param');

  return (
    <ContentBox>
      <Box p="24px">
        <FormControl>
          <Text fontSize={'14px'} fontWeight="500">
            Client ID
          </Text>
          <Spacer p="1" />
          <Flex>
            <InputGroup>
              <Input
                fontSize="16px"
                color={COLORS.GRAY.GRAY_600}
                isReadOnly
                value={applicationId}
                type={'text'}
              />
              <InputRightElement gap={2}>
                <ClipboardButton value={applicationId} />
              </InputRightElement>
            </InputGroup>
          </Flex>
        </FormControl>
        <Spacer p="4" />
        <FormControl>
          <HStack>
            <Text fontSize={'14px'} fontWeight="500">
              Client Secret
            </Text>
            <Tooltip
              label="Please store your client secret securely in your backend. For your application's security, we only show it once. You must refresh the secret again to see it."
              hasArrow
              placement="bottom-start"
            >
              <span>
                <FiInfo />
              </span>
            </Tooltip>
          </HStack>
          <Spacer p="1" />
          <Flex>
            <ClientSecretField applicationName={applicationName} />
          </Flex>
        </FormControl>
      </Box>
    </ContentBox>
  );
};

export const ApplicationDetails = ({
  applicationName,
  applicationType,
}: {
  applicationName?: string;
  applicationType?: ApplicationStatus;
}) => (
  <PageHeading>
    {applicationName}
    {applicationType && (
      <Box as="span" ml={4}>
        <ApplicationStatusBadge applicationType={applicationType} />
      </Box>
    )}
  </PageHeading>
);

export const CredentialsPage = () => {
  const { applicationId } = useParams<{ applicationId: string }>();
  const { isLoading: isRoleLoading } = useUserRole();
  const roleCanManageSftp = usePermissions(Object.values(PERMISSION.SFTP));

  if (!applicationId) throw new Error('no application id in url param');

  const { application, isLoading } = useApplication(applicationId);

  const sftpCredentialsFormRef = useRef<HTMLFormElement>(null);

  const isFlatfileEnabled = application !== undefined && application.features.flatFileEnabled;
  const showSftpForm = isFlatfileEnabled && roleCanManageSftp;

  const { get } = useSftpCredentials({ disabled: !showSftpForm });


  if (!application && !isLoading) {
    return <NotFoundPage />;
  }

  const scrollToSftpCredentials = () => {
    sftpCredentialsFormRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  const isPageLoading = isLoading || isRoleLoading;

  return (
    <>
      <Box>
        <PageContainer>
          {isPageLoading && (
            <Center>
              <Spinner />
            </Center>
          )}
          {!isPageLoading && application && (
            <Flex flexDir="column" maxW={'2xl'} gap="60px">
              <Flex gap="32px" flexDir="column">
                <ApplicationDetails
                  applicationName={application.name}
                  applicationType={application.status}
                />

                <Flex flexDir="column" gap="40px">
                  {application.isSandbox() && <SandboxAlert />}

                  {showSftpForm && get.data && !get.data.isConnected && (
                    <Banner
                      type="warning"
                      icon={warningInfoIcon}
                      fullWidth
                      content={
                        <>
                          Your SFTP Credentials are currently invalid.{' '}
                          <Button
                            variant="link"
                            color="warning.700"
                            textDecor="underline"
                            onClick={scrollToSftpCredentials}
                          >
                            Update Now
                          </Button>
                        </>
                      }
                    />
                  )}

                  <CopiableTextForms applicationName={application.name} />

                  <ProductCheckBoxesForm scopes={application.scopes} />
                </Flex>
              </Flex>

              <PermissionRequired
                match={Object.values(PERMISSION.RedirectUris)}
              >
                <RedirectURIs
                  applicationId={applicationId}
                  urls={application.redirect_uris || []}
                  scopes={application.scopes || []}
                >
                  <RedirectURIsForm />
                </RedirectURIs>
              </PermissionRequired>

              {showSftpForm && (
                <SftpCredentialsForm formRef={sftpCredentialsFormRef} />
              )}
              <Spacer p="5" />
            </Flex>
          )}
        </PageContainer>
      </Box>
    </>
  );
};
