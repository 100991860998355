import { useQuery } from '@tanstack/react-query';

import { useSandboxStore } from '../state/sandboxStore';
import { useConnectionApi } from './useConnectionApi';
import { Connection } from '../model';
import { useParams } from 'react-router-dom';
import {
  FINCH_SANDBOX_CONNECTION_LIMIT,
  PROVIDER_SANDBOX_CONNECTION_LIMIT,
} from '@finch-api/common/dist/internal/sandbox';

export const useConnections = () => {
  const { applicationId } = useParams<{ applicationId: string }>();

  const api = useConnectionApi();

  const { isLoading, data = [] } = useQuery({
    queryKey: ['connections-v2', applicationId],
    queryFn: () => api.getConnections(applicationId),
  });

  const connections = data.map((connection) => new Connection(connection));

  return { connections, isLoading };
};

const isFinchSandbox = (connection: Connection) =>
  connection.hasFinchSandboxKinds() && !connection.isLegacySandboxProvider();

const isProviderSandbox = (connection: Connection) =>
  !connection.hasFinchSandboxKinds() && !connection.isLegacySandboxProvider();

const notDisconnected = (connection: Connection) =>
  !connection.isDisconnected();

export const useSandboxConnections = () => {
  const { connections, isLoading } = useConnections();
  const { selectedSandbox, setSelectedSandbox } = useSandboxStore();

  const defaultInfo = {
    connections,
    isLoading,
    used: 0,
    selectedSandbox,
    setSelectedSandbox,
    limit: null,
  };

  if (selectedSandbox === 'finch') {
    const sandboxConnections = connections.filter(isFinchSandbox);

    return {
      ...defaultInfo,
      connections: sandboxConnections,
      isLoading,
      used: sandboxConnections.filter(notDisconnected).length,
      limit: FINCH_SANDBOX_CONNECTION_LIMIT,
    };
  }

  if (selectedSandbox === 'provider') {
    const sandboxConnections = connections.filter(isProviderSandbox);

    return {
      ...defaultInfo,
      connections: sandboxConnections,
      isLoading,
      used: sandboxConnections.filter(notDisconnected).length,
      limit: PROVIDER_SANDBOX_CONNECTION_LIMIT,
    };
  }

  return defaultInfo;
};
