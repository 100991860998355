import { Stack, Heading, HStack, Tooltip, Box } from '@chakra-ui/react';
import { ContentBox } from '../../components/ContentBox';
import { useSelectedTabStore } from '../state/selectedTab';
import { ProviderWithImplementation } from '../types';
import { tabDetails } from '../constants';
import { useProviderConfigurations } from '../hooks/useProviderConfigurations';
import { TopBar } from '../components/TopBar';
import { SideNavigation } from '../components/SideNavigation';
import { ProviderSearch } from '../components/ProviderSearch';
import { UnsavedSettingsModal } from '../components/UnsavedSettingsModal';
import { Loading } from '../../components/Loading/Loading';
import { InfoIcon } from '../../shared/InfoIcon';
import { useEffect, useState, useTransition } from 'react';
import isNil from 'lodash/isNil';
import { useProviderSearchStore } from '../state/providerSearch';
import { ProvidersListPage } from './ProvidersPage';
import { SettingsPage } from './SettingsPage';
import { useProviderSettings } from '../hooks/useProviderSettings';
import { SideNavigationWithContent } from '../../shared/SideNavigationWithContent';
import { PageContainer } from '../../components/PageContainer';

export const ConfigurePage = () => {
  const selectedTab = useSelectedTabStore((store) => store.selectedTab);
  const [isProvidersPending, startTransition] = useTransition();

  const [currentProviders, setCurrentProviders] = useState<
    ProviderWithImplementation[]
  >([]);

  const searchTerm = useProviderSearchStore((store) => store.searchTerm);

  const {
    isLoading: isLoadingProviderConfiguration,
    currentProviders: providers,
  } = useProviderConfigurations();

  const { isLoading: isLoadingSettings } = useProviderSettings();

  const isSortMode = selectedTab === 'Pinned';

  const isLoading = isLoadingProviderConfiguration || isLoadingSettings;

  useEffect(() => {
    /**
     * Wrap in a start transition to give render priority to the UI elements that change the set of providers
     * (e.g. Search and navigation)
     *
     * Except when in sort mode, because we want the sort to be snappy
     */
    if (isSortMode) {
      setCurrentProviders(providers);
    } else {
      startTransition(() => {
        setCurrentProviders(providers);
      });
    }
  }, [providers, isSortMode]);

  const selectedTabInfo = tabDetails[selectedTab];

  return (
    <PageContainer
      h="calc(100vh - 69px)"
      pb={{ base: '0', md: '0' }}
      overflow={{ base: 'scroll', md: 'hidden' }}
    >
      <Stack spacing="36px" h="full">
        <TopBar />
        <SideNavigationWithContent
          nav={<SideNavigation />}
          containerProps={{ overflow: { base: 'scroll', md: 'hidden' } }}
          mainContentProps={{
            h: 'full',
            overflow: { base: 'visible', md: 'scroll' },
          }}
        >
          {isLoading || (isProvidersPending && !currentProviders.length) ? (
            <Loading message="Your configurations are being loaded" />
          ) : (
            <Stack flex="1" h="full" spacing="0" w="full">
              <ContentBox p="24px" borderRadius="0" borderTopRadius="8px">
                <Stack spacing="16px">
                  <HStack>
                    <Heading size="sm">{selectedTabInfo.title}</Heading>
                    {selectedTabInfo.info && (
                      <Tooltip label={selectedTabInfo.info} placement="top">
                        <Box>
                          <InfoIcon />
                        </Box>
                      </Tooltip>
                    )}
                  </HStack>

                  {selectedTabInfo.isIntegrationsList && (
                    <ProviderSearch
                      isDisabled={
                        currentProviders.length === 0 && isNil(searchTerm)
                      }
                    />
                  )}
                </Stack>
              </ContentBox>

              {selectedTabInfo.isIntegrationsList && (
                <ProvidersListPage providers={currentProviders} />
              )}

              {selectedTab === 'Settings' && <SettingsPage />}
              <UnsavedSettingsModal />
            </Stack>
          )}
        </SideNavigationWithContent>
      </Stack>
    </PageContainer>
  );
};
