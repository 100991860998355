import {
  Box,
  Center,
  Tag,
  Tooltip,
  Text as ChakraText,
  TextProps,
} from '@chakra-ui/react';
import styled from '@emotion/styled';

import { ExternalJobStatus } from '@finch-api/common/dist/external/dashboard/job-status';
import { ConnectionCategory } from '@finch-api/common/dist/external/dashboard/connection-status';
import { COLORS } from '../../constant';
import { Code } from '../../components/Code/Code';
import {
  JobStatusTag,
  JobStatusVariant,
} from '../../components/Tag/JobStatusTag';
import { ReactNode } from 'react';
import { AssistedIcon } from '../../shared/AssistedIcon';
import { AutomatedIcon } from '../../shared/AutomatedIcon';
import { ClipboardButton } from 'components/ClipboardButton';

const Text = styled.div`
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  cursor: inherit;
  color: ${COLORS.FINCH.BLACK};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CodeBlock = styled(Code)`
  color: ${COLORS.FINCH.BLACK};
  display: flex;
  gap: 8px;
`;

const connectionCategoryToIdCellInfoMap: Record<
  ConnectionCategory,
  { label: string; icon: ReactNode }
> = {
  automated: {
    label: 'Automated Connection',
    icon: <AutomatedIcon />,
  },
  assisted: {
    label: 'Assisted Connection',
    icon: <AssistedIcon />,
  },
};

export const CellWithClipboard = ({
  text,
  clipboardValue,
  textProps,
}: {
  text: string;
  textProps?: TextProps;
  clipboardValue: string;
}) => {
  return (
    <CodeBlock>
      <Center gap="2">
        <ChakraText {...textProps}>{text}</ChakraText>
        <ClipboardButton value={clipboardValue} />
      </Center>
    </CodeBlock>
  );
};

export const IdCell = ({
  id,
  connectionCategory = ConnectionCategory.Automated,
  batchedIndividuals,
}: {
  id: string;
  connectionCategory?: ConnectionCategory;
  batchedIndividuals?: number;
}) => {
  return (
    <CodeBlock>
      <Center gap="2">
        <Tooltip
          placement="top-start"
          label={connectionCategoryToIdCellInfoMap[connectionCategory].label}
        >
          <div>
            {connectionCategoryToIdCellInfoMap[connectionCategory].icon}
          </div>
        </Tooltip>

        {id}

        {batchedIndividuals && (
          <Tooltip
            placement="top-start"
            label={
              batchedIndividuals > 1
                ? `This job includes a batch request for ${batchedIndividuals} individuals`
                : `This job includes a request for ${batchedIndividuals} individual`
            }
          >
            <Tag
              size="sm"
              fontWeight="600"
              bg={COLORS.GRAY.GRAY_300}
              color={COLORS.FINCH.GRAY}
              px="5px"
              minW={0}
            >
              {batchedIndividuals}
            </Tag>
          </Tooltip>
        )}
      </Center>
    </CodeBlock>
  );
};

export const TextCell = ({ text }: { text: string }) => {
  return (
    <Box>
      <Text title={text}>{text}</Text>
    </Box>
  );
};

const statusMap: {
  [k in ExternalJobStatus]: JobStatusVariant;
} = {
  [ExternalJobStatus.PENDING]: 'pending',
  [ExternalJobStatus.IN_PROGRESS]: 'in_progress',
  [ExternalJobStatus.COMPLETE]: 'success',
  [ExternalJobStatus.PERMISSION_ERROR]: 'permission',
  [ExternalJobStatus.REAUTH_ERROR]: 'reauth',
  [ExternalJobStatus.ERROR]: 'error',
  [ExternalJobStatus.PARTIAL_ERROR]: 'partial_error',
};

export const StatusCell = ({ status }: { status: ExternalJobStatus }) => {
  const variant = statusMap[status];

  if (!variant) return null;

  return <JobStatusTag variant={variant} />;
};
