import { Table, Tbody, Td, Tr } from '@chakra-ui/react';
import { useMemo } from 'react';
import { FiChevronRight as ChevronRight } from 'react-icons/fi';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import type { CellContext, Row } from '@tanstack/react-table';
import { Spacer } from '../shared/Spacer';
import { RequestDetailsDrawer } from './LogDetailsDrawer';

import { CompanyCell, DateCell, PathCell } from './LogsTableCells';
import { SearchFilters } from './SearchFilters';
import { Log } from '@finch-api/developer-dashboard-common';
import { Pagination } from './Pagination';
import { EmptyTableRowStateMessage, LoadingState } from './TableStates';
import { useActivityUrlParamSync } from './hooks/useActivityUrlParamSync';
import { useActivityStore } from './state/activityStore';
import { useActivityDetails } from './hooks/useActivityDetails';
import { TableHeaderContent } from '../components/TableHeaderContent';
import { filterFns } from '../components/TableFilters';
import { ContentBox } from '../components/ContentBox';
import { HttpStatusTag } from '../components/Tag/HttpStatusTag';

const TableRow = ({ row, onClick }: { row: Row<Log>; onClick: () => void }) => {
  return (
    <>
      <Tr onClick={onClick} key={row.id} _hover={{ cursor: 'pointer' }}>
        {row.getVisibleCells().map((cell) => (
          <Td key={cell.id}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </Td>
        ))}
      </Tr>
    </>
  );
};

export const LogsTable = () => {
  const { loading, logs, setSelectedLog } = useActivityStore();

  useActivityUrlParamSync();
  useActivityDetails();

  const columns = useMemo(
    () => [
      {
        header: 'Status',
        accessorKey: 'status',
        cell: (props: CellContext<Log, Log['status']>) => (
          <HttpStatusTag status={props.getValue()} />
        ),
        width: '150px',
      },
      {
        header: 'Date',
        accessorKey: 'timestamp',
        filter: 'between',
        cell: (props: CellContext<Log, Log['timestamp']>) => (
          <DateCell date={props.getValue()} />
        ),
        width: '150px',
      },
      {
        header: 'Path',
        accessorKey: 'fullUrl',
        cell: (props: CellContext<Log, Log['fullUrl']>) => (
          <PathCell row={props.row} />
        ),
        width: '150px',
      },
      {
        header: 'Company',
        accessorKey: 'company',
        cell: (props: CellContext<Log, Log['company']>) => (
          <CompanyCell company={props.getValue()} />
        ),
        width: '150px',
      },
      {
        accessorKey: 'payrollProvider',
      },
      {
        // This has no header because we want these to be static arrows.
        id: 'arrowIcon',
        cell: () => <ChevronRight />,
        width: '5px',
      },
    ],
    [],
  );

  const { getHeaderGroups, getRowModel } = useReactTable({
    columns,
    state: {
      columnVisibility: {
        payrollProvider: false,
      },
    },
    data: logs,
    getCoreRowModel: getCoreRowModel(),
    filterFns,

    // sorting is disabled until we build sorting into the API request for the logs.
    // The logs are paginated in the backend so sorting as is today will sort only the current page
    // which can be confusing and misleading to users
    enableSorting: false,
  });

  const rows = getRowModel().rows;

  return (
    <>
      <SearchFilters />
      <Spacer p="4" />
      {(() => {
        if (loading) {
          return <LoadingState />;
        }

        if (rows.length === 0) {
          return <EmptyTableRowStateMessage />;
        }

        return (
          <ContentBox overflow={'scroll'}>
            <Table border="none">
              <TableHeaderContent headerGroups={getHeaderGroups()} />
              <Tbody>
                {rows.map((row) => {
                  return (
                    <TableRow
                      onClick={() => {
                        setSelectedLog(row.original);
                      }}
                      key={row.id}
                      row={row}
                    />
                  );
                })}
              </Tbody>
            </Table>
          </ContentBox>
        );
      })()}

      <RequestDetailsDrawer />
      <Spacer p="2" />
      <Pagination />
    </>
  );
};
