import { Box, HStack, Spacer, Text } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { OverflowTooltip } from '../../shared/OverflowTooltip';
import { Caption } from '../../shared/Typography';
import { format } from '../../shared/utils/dateFormatter';
import { AssistedIcon } from '../../shared/AssistedIcon';
import { AutomatedIcon } from '../../shared/AutomatedIcon';
import {
  AccountStatus,
  ConnectionCategory,
} from '@finch-api/common/dist/external/dashboard/connection-status';
import { COLORS } from '../../constant';
import type { CellContext } from '@tanstack/react-table';
import { ConnectionsTableColumn } from '../types';
import { ProviderIcon } from '../../components/Iconagraphy/ProviderIcon';
import { ConnectionStatusIcon } from './ConnectionStatusIcon';
import { ConnectionStatusTag } from '../../components/Tag/ConnectionStatusTag';

const isDisconnected = ({ status }: ConnectionsTableColumn) =>
  AccountStatus.DISCONNECTED === status;

const CompanyId = ({
  children,
  disconnected,
}: {
  children: ReactNode;
  disconnected: boolean;
}) => {
  return (
    <Text
      as="span"
      fontFamily="Roboto Mono, monospace"
      fontWeight={400}
      fontSize="14px"
      color={disconnected ? COLORS.GRAY.GRAY_500 : COLORS.GRAY.GRAY_600}
    >
      {children}
    </Text>
  );
};

export const IdCell = ({
  props,
}: {
  props: CellContext<ConnectionsTableColumn, string>;
}) => {
  const disconnected = isDisconnected(props.row.original);

  return (
    <Box isTruncated color={disconnected ? COLORS.GRAY.GRAY_500 : 'initial'}>
      <Caption>{props.getValue()}</Caption>
    </Box>
  );
};

export const CompanyCell = ({
  props,
}: {
  props: CellContext<ConnectionsTableColumn, { name: string; id: string }>;
}) => {
  const disconnected = isDisconnected(props.row.original);

  return (
    <Box color={disconnected ? COLORS.GRAY.GRAY_500 : 'initial'}>
      <OverflowTooltip>
        <Caption>{props.getValue().name}</Caption>
      </OverflowTooltip>
      <Spacer mt="7px" />
      <HStack minH="20px">
        <CompanyId disconnected={disconnected}>{props.getValue().id}</CompanyId>
        <ConnectionStatusIcon status={props.row.original.status} />
      </HStack>
    </Box>
  );
};

export const ConnectedStatusCell = ({
  props,
}: {
  props: CellContext<ConnectionsTableColumn, AccountStatus>;
}) => {
  return <ConnectionStatusTag status={props.getValue()} />;
};

const ConnectionType = ({
  kind,
  disconnected,
}: {
  kind: ConnectionCategory;
  disconnected: boolean;
}) => {
  const opacity = disconnected ? 0.33 : 1.0;

  switch (kind) {
    case ConnectionCategory.Assisted:
      return (
        <HStack opacity={opacity}>
          <AssistedIcon />
          <Caption>Assisted</Caption>
        </HStack>
      );
    case ConnectionCategory.Automated:
      return (
        <HStack opacity={opacity}>
          <AutomatedIcon />
          <Caption>Automated</Caption>
        </HStack>
      );
  }
};

export const ConnectionTypeCell = ({
  props,
}: {
  props: CellContext<ConnectionsTableColumn, ConnectionCategory[]>;
}) => {
  const disconnected = isDisconnected(props.row.original);

  return (
    <Box>
      <HStack spacing="16px">
        {props
          .getValue()
          .sort()
          .reverse()
          .map((value, index) => (
            <ConnectionType
              key={index}
              kind={value}
              disconnected={disconnected}
            />
          ))}
      </HStack>
    </Box>
  );
};

export const DateCell = ({
  props,
}: {
  props: CellContext<ConnectionsTableColumn, number | undefined>;
}) => {
  const disconnected = isDisconnected(props.row.original);
  const value = props.getValue();
  let content = '';

  if (!value) {
    content = '—';
  } else {
    content = format(new Date(value));
  }

  return (
    <Box isTruncated color={disconnected ? COLORS.GRAY.GRAY_500 : 'initial'}>
      <Caption>{content}</Caption>
    </Box>
  );
};

export const ProviderNameCell = ({
  props,
}: {
  props: CellContext<
    ConnectionsTableColumn,
    { id: string; name: string; icon: string }
  >;
}) => {
  const disconnected = isDisconnected(props.row.original);

  return (
    <Box opacity={disconnected ? 0.5 : 1.0}>
      <HStack>
        <ProviderIcon
          src={props.getValue().icon}
          provider={props.getValue().name}
        />
        <OverflowTooltip>
          <Caption>{props.getValue().name}</Caption>
        </OverflowTooltip>
      </HStack>
    </Box>
  );
};
