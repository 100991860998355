import { Container, type ContainerProps } from '@chakra-ui/react';
import { ReactNode } from 'react';

export const PageContainer = ({
  children,
  ...props
}: ContainerProps & { children: ReactNode }) => {
  return (
    <Container maxW={'8xl'} p={{ base: 6, md: 12 }} {...props}>
      {children}
    </Container>
  );
};
