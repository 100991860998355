import { Box, IconButton } from '@chakra-ui/react';
import { COLORS } from '../constant';

import { CloseIcon } from '../shared/icons/CloseIcon';
import { ErrorIcon } from '../shared/icons/ErrorIcon';
import { InfoIcon } from '../shared/icons/InfoIcon';
import { WarningIcon } from '../shared/icons/WarningIcon';

type BannerTheme = 'info' | 'warn' | 'error';

const TYPE_TO_ICON: Record<BannerTheme, React.ReactNode> = {
  info: <InfoIcon />,
  warn: <WarningIcon />,
  error: <ErrorIcon />,
};

const TYPE_TO_COLOR: Record<BannerTheme, string> = {
  info: COLORS.FINCH.PURPLE,
  warn: COLORS.ORANGE,
  error: COLORS.FINCH.RED,
};

export const Banner = ({
  type = 'info',
  onClose = () => {},
  children,
}: {
  type?: BannerTheme;
  onClose: () => void;
  children: React.ReactNode;
}) => {
  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      color="white"
      backgroundColor={TYPE_TO_COLOR[type]}
      minHeight="40px"
      padding={{ base: '8px 16px', sm: '8px 32px' }}
      overflow={'hidden'}
      justifyContent={'space-between'}
      gap="16px"
      fontSize="14px"
    >
      <Box
        textAlign={{ base: 'center', sm: 'left' }}
        display={'flex'}
        gap="6px"
        alignItems={'center'}
      >
        <div>{TYPE_TO_ICON[type]}</div>
        {children}
      </Box>
      <IconButton
        variant={'plainIcon'}
        color="white"
        size="xs"
        aria-label="close-btn"
        icon={<CloseIcon />}
        onClick={onClose}
      />
    </Box>
  );
};
