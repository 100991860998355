import {
  Box,
  Flex,
  InputGroup,
  FormControl,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  InputRightElement,
  useClipboard,
  Text,
  Stack,
} from '@chakra-ui/react';
import { FiCheckCircle } from 'react-icons/fi';
import { TransparentIconButton } from '../../shared/TransparentIconButton';
import { AlertBodyText } from '../../shared/Typography';
import { Spacer } from '../../shared/Spacer';
import { RefObject } from 'react';
import { COLORS } from '../../constant';
import { CopyIcon } from '../../shared/icons/CopyIcon';

export const ClipboardButton = ({
  buttonRef,
  value,
}: {
  buttonRef?: RefObject<HTMLButtonElement>;
  value: string;
}) => {
  const { hasCopied, onCopy } = useClipboard(value);
  return hasCopied ? (
    <TransparentIconButton
      ref={buttonRef}
      aria-label="copied"
      icon={<FiCheckCircle />}
      onClick={onCopy}
      variant="icon"
    />
  ) : (
    <TransparentIconButton
      ref={buttonRef}
      aria-label="copy"
      icon={<CopyIcon />}
      onClick={onCopy}
      variant="icon"
    />
  );
};

export const WebhookSecretModal = ({
  isOpen,
  onClose,
  endpointURL,
  secret,
}: {
  isOpen: boolean;
  onClose: () => void;
  endpointURL: string;
  secret: string;
}) => {
  const warningText = () => (
    <>
      This secret is used to sign/verify the webhook payload sent to{' '}
      <Text as="span" fontWeight="bold">
        {endpointURL}
      </Text>
      . It is recommended that you store this secret in a secure location. This
      is the only time you will be able to view it, so make sure to copy it
      before closing this modal.
    </>
  );

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            fontFamily="Inter"
            fontSize="18px"
            fontWeight="semibold"
            color={COLORS.FINCH.BLACK}
            alignSelf="center"
          >
            New Endpoint Secret
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              <Stack gap="24px">
                <AlertBodyText>{warningText()}</AlertBodyText>
                <FormControl>
                  <Flex>
                    <InputGroup>
                      <Input isReadOnly value={secret} type={'text'} />
                      <InputRightElement gap={2}>
                        <ClipboardButton value={secret} />
                      </InputRightElement>
                    </InputGroup>
                  </Flex>
                </FormControl>
              </Stack>
              <Spacer p="12px" />
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
