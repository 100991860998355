import { ReactNode, useState } from 'react';
import styled from '@emotion/styled';
import first from 'lodash/first';

import { JobStatusTable } from './JobsTable';
import { COLORS } from '../../constant';

import {
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { ConnectionAccountDetail, JobEntry } from '../types';
import { AssistedSetupStatus } from './AssistedSetupStatus';
import { ConnectionDetailTab, connectionDetailTabs } from '../constants';
import { ConnectionOverviewPanel } from './ConnectionOverviewPanel';
import { isAssistedImplementationKind } from '@finch-api/common/dist/internal/connect/authorize';
import { AccountStatus } from '@finch-api/common/dist/external/dashboard/connection-status';
import { FeatureFlag } from 'constant/feature-flags';
import { useFlag } from '@unleash/proxy-client-react';
import { ApiExplorer } from 'connection-detail-v2/pages/api-explorer/pages';
import { ConnectSessions } from './ConnectSessions';
import { useApplication } from 'applications/hooks';

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const TAB_TO_COMPONENT: Record<
  ConnectionDetailTab,
  (props: {
    connectionDetail: ConnectionAccountDetail;
    jobs: JobEntry[];
    statusLog?: ConnectionAccountDetail['statusTimeline'][number];
    assistedProduct?: ConnectionAccountDetail['products'][number];
  }) => ReactNode
> = {
  [ConnectionDetailTab.OVERVIEW]: ({
    connectionDetail,
    jobs,
  }: {
    connectionDetail: ConnectionAccountDetail;
    jobs: JobEntry[];
  }) => (
    <Wrapper>
      <ConnectionOverviewPanel
        connectionDetail={connectionDetail}
        jobs={jobs}
      />
    </Wrapper>
  ),
  [ConnectionDetailTab.JOB_HISTORY]: ({ jobs }: { jobs: JobEntry[] }) => (
    <JobStatusTable isLoading={false} jobs={jobs} />
  ),
  [ConnectionDetailTab.ASSISTED_SETUP_STATUS]: ({
    assistedProduct,
    statusLog,
  }: {
    assistedProduct?: ConnectionAccountDetail['products'][number];
    statusLog?: ConnectionAccountDetail['statusTimeline'][number];
  }) => (
    <AssistedSetupStatus
      status={assistedProduct?.status}
      message={assistedProduct?.statusMessage}
      lastUpdatedAt={statusLog ? new Date(statusLog?.createdAt) : undefined}
    />
  ),
  [ConnectionDetailTab.API_EXPLORER]: () => <ApiExplorer />,
  [ConnectionDetailTab.CONNECT_SESSIONS]: () => <ConnectSessions />,
};

const ConnectionDetailTabs = ({
  connectionDetail,
  jobs,
}: {
  connectionDetail: ConnectionAccountDetail;
  jobs: JobEntry[];
}) => {
  const { application } = useApplication();
  const statusLog = first(connectionDetail?.statusTimeline);
  const [selectedTab, setSelectedTab] = useState(ConnectionDetailTab.OVERVIEW);
  const assistedProduct = connectionDetail?.products.find((product) =>
    isAssistedImplementationKind(product.implementationKind),
  );
  const isApiExplorerEnabled = useFlag(FeatureFlag.DashboardApiExplorer);

  const isTabVisible = (key: number) => {
    switch (key) {
      case ConnectionDetailTab.ASSISTED_SETUP_STATUS:
        return connectionDetail.products.some((product) =>
          isAssistedImplementationKind(product.implementationKind),
        );
      case ConnectionDetailTab.API_EXPLORER:
        return isApiExplorerEnabled;
      case ConnectionDetailTab.CONNECT_SESSIONS:
        return application?.features.flatFileEnabled;
      default:
        return true;
    }
  };

  const onTabChange = (index: number) => {
    setSelectedTab(index);
  };

  const visibleTabs = Object.entries(connectionDetailTabs).filter(
    ([key]) =>
      isTabVisible(parseInt(key, 10)) &&
      // Only show the overview tab if the connection is disconnected
      (parseInt(key, 10) === ConnectionDetailTab.OVERVIEW ||
        connectionDetail.connectionStatus !== AccountStatus.DISCONNECTED),
  );

  return (
    <Tabs
      flexGrow={1}
      index={selectedTab}
      onChange={onTabChange}
      // marginTop="-43px" // NOTE: some design things that need to be thought out for long company names
    >
      <TabList gap={6}>
        {visibleTabs.map(([key, value]) => (
          <Tab
            key={key}
            fontSize="14px"
            fontWeight="semibold"
            color={COLORS.FINCH.BLACK}
            padding="0 0 12px 0"
          >
            {value}
          </Tab>
        ))}
      </TabList>
      <TabPanels>
        {visibleTabs.map(([key]) => (
          <TabPanel key={key} paddingLeft="0" paddingRight="0">
            {TAB_TO_COMPONENT[parseInt(key, 10) as ConnectionDetailTab]({
              jobs,
              statusLog,
              assistedProduct,
              connectionDetail,
            })}
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};

export const ConnectionDetailContent = ({
  connectionDetail,
  jobs,
}: {
  connectionDetail: ConnectionAccountDetail;
  jobs: JobEntry[];
}) => {
  return (
    <Flex
      gap="24px"
      direction={{
        base: 'column',
        lg: 'row',
      }}
      align={{ base: 'stretch', lg: 'flex-start' }}
    >
      <ConnectionDetailTabs connectionDetail={connectionDetail} jobs={jobs} />
    </Flex>
  );
};
