import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Button,
  Text,
  Box,
  Stack,
  Input,
} from '@chakra-ui/react';
import { COLORS } from '../../constant';
import { AlertBodyText } from '../../shared/Typography';
import { useDeleteWebhookEndpoint } from '../hooks';
import { useState } from 'react';
import { useStatusToast } from '../../shared/StatusToast';

const DELETE_CONFIRMATION_TEXT = 'DELETE ENDPOINT';

export const DeleteEndpointConfirmationModal = ({
  isOpen,
  onClose,
  applicationId,
  endpointId,
  endpointURL,
  onWebhookDeleted,
}: {
  isOpen: boolean;
  onClose: () => void;
  applicationId: string;
  endpointId: string;
  endpointURL: string;
  onWebhookDeleted: () => void;
}) => {
  const toast = useStatusToast('bottom');
  const { loading, mutate } = useDeleteWebhookEndpoint();
  const [deleteConfirmationText, setDeleteConfirmationText] = useState('');
  const [isDeleteButtonDisabled, setIsDeleteButtonDisabled] = useState(true);

  const warningText = (
    <Stack gap="24px">
      <Text>
        You are deleting a registered webhook endpoint for your application. If
        your application still relies on this endpoint, things could break.
      </Text>
      <Text>
        Are you sure you want to do this? You will no longer receive any webhook
        events at{' '}
        <Text as="span" fontWeight="bold">
          {endpointURL}
        </Text>
      </Text>
      <Text>
        Type{' '}
        <Text as="span" fontWeight="bold">
          {`"${DELETE_CONFIRMATION_TEXT}”`}
        </Text>{' '}
        without quotations to confirm this deletion.
      </Text>
    </Stack>
  );

  const onDeleteConfirmationTextChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setDeleteConfirmationText(e.target.value);
    setIsDeleteButtonDisabled(!(e.target.value === DELETE_CONFIRMATION_TEXT));
  };

  const deleteEndpoint = async () => {
    const { error } = await mutate(applicationId, endpointId);

    if (error) {
      toast({
        wasSuccessful: false,
        message: `Failed to delete ${endpointURL}`,
      });
    } else {
      toast({
        wasSuccessful: true,
        message: `Endpoint ${endpointURL} has been deleted`,
      });
      onWebhookDeleted();
      onClose();
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered size="lg">
        <ModalOverlay />
        <ModalContent maxWidth={'576px'}>
          <ModalHeader width={'100%'}>
            <Box
              fontFamily="Inter"
              fontSize="18px"
              fontWeight="semibold"
              color={COLORS.FINCH.RED}
              textAlign={'center'}
              padding={'12px'}
              width={'100%'}
              backgroundColor="error.c100"
              borderRadius={'8px'}
              lineHeight="16px"
            >
              Confirm Endpoint Deletion
            </Box>
          </ModalHeader>

          <ModalBody>
            <Box>
              <Stack gap="24px">
                <AlertBodyText>{warningText}</AlertBodyText>
                <Input
                  placeholder="DELETE ENDPOINT"
                  value={deleteConfirmationText}
                  onChange={onDeleteConfirmationTextChange}
                />
              </Stack>
            </Box>
          </ModalBody>

          <ModalFooter>
            <Box display="flex" gap="16px">
              <Button size="md" variant="outline" onClick={onClose}>
                Cancel
              </Button>
              <Button
                variant="danger"
                isLoading={loading}
                isDisabled={isDeleteButtonDisabled}
                onClick={deleteEndpoint}
              >
                Delete Endpoint
              </Button>
            </Box>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
