import { Box, Text, HStack, Skeleton } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { ConnectionDetailContent } from './ConnectionDetailContent';
import { Code } from '../../components/Code/Code';
import { COLORS } from '../../constant';
import back_arrow from '../../assets/images/icons/back_arrow.svg';
import { AccountStatus } from '@finch-api/common/dist/external/dashboard/connection-status';
import { useHistory } from 'react-router-dom';
import { useConnectionDetails } from '../hooks/useConnectionDetail';
import { useConnectionJobs } from '../hooks/useConnectionJobs';
import { ConnectionDetailActionMenu } from './ConnectionDetailActionMenu';
import { Loading } from '../../components/Loading/Loading';
import { useEffect } from 'react';

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const TextBlock = styled.div`
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  color: ${COLORS.FINCH.BLACK};
`;

const CodeBlock = styled(Code)`
  color: ${COLORS.GRAY.GRAY_600};
`;

const GoBackButton = styled.button`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: ${COLORS.FINCH.PURPLE};
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const wouldAccountRequireReAuth = (accountStatus: AccountStatus) => {
  return accountStatus === AccountStatus.REAUTH;
};

export const ConnectionDetailScreen = ({
  applicationId,
  connectionId,
}: {
  applicationId: string;
  connectionId: string;
}) => {
  const {
    connectionDetail,
    isLoading: isConnectionDetailLoading,
    error: connectionDetailError,
  } = useConnectionDetails({
    applicationId,
    connectionId,
  });

  const { jobs, isLoading: isJobsLoading } = useConnectionJobs({
    applicationId,
    connectionId,
  });

  const isLoading = isConnectionDetailLoading || isJobsLoading;

  const history = useHistory();

  useEffect(() => {
    if (connectionDetailError?.response?.data?.name === 'not_found_error') {
      history.push(`/app/applications/${applicationId}/connections`);
    }
  }, [connectionDetailError, applicationId, history]);

  const onClickGoBack = () => {
    if (history.location.state?.navigatedFromConnectionsTable) {
      history.goBack();
    } else {
      history.push(`/app/applications/${applicationId}/connections`);
    }
  };

  return (
    <>
      <GoBackButton onClick={onClickGoBack}>
        <img src={back_arrow} alt="back button" />
        Back to Connections
      </GoBackButton>
      <Box>
        <HStack gap={6} justifyContent="space-between" wrap="wrap" mb="24px">
          <Header>
            {isLoading ? (
              <Skeleton height="29px" width="256px" rounded="lg" />
            ) : (
              <>
                <TextBlock>{connectionDetail?.companyName}</TextBlock>
                <HStack wrap="wrap">
                  <Text fontSize={12} fontFamily="Inter" fontWeight={500}>
                    Company ID
                  </Text>
                  <CodeBlock>{connectionDetail?.companyId}</CodeBlock>
                </HStack>
              </>
            )}
          </Header>
          {!isLoading &&
            connectionDetail &&
            connectionDetail.connectionStatus !==
              AccountStatus.DISCONNECTED && (
              <ConnectionDetailActionMenu connectionDetail={connectionDetail} />
            )}
        </HStack>
        {isLoading && (
          <Wrapper>
            <Loading message="We’re getting your connection details ready." />
          </Wrapper>
        )}
        {!isLoading && connectionDetail && (
          <ConnectionDetailContent
            connectionDetail={connectionDetail}
            jobs={jobs}
          />
        )}
      </Box>
    </>
  );
};
