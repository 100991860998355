import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Text,
  Stack,
  Tag,
  Divider,
  Spinner,
  AbsoluteCenter,
  Tooltip,
  HStack,
  Flex,
  Spacer,
  CloseButton,
} from '@chakra-ui/react';
import now from 'lodash/now';
import millisecondsToMinutes from 'date-fns/millisecondsToMinutes';
import millisecondsToSeconds from 'date-fns/millisecondsToSeconds';
import millisecondsToHours from 'date-fns/millisecondsToHours';
import subMilliseconds from 'date-fns/subMilliseconds';
import { COLORS } from '../../constant';
import { WebhookMessage } from '@finch-api/developer-dashboard-common/dist/api/webhook';
import { Caption } from '../../shared/Typography';
import { format } from '../../shared/utils/dateFormatter';
import ReactJson from 'react-json-view';
import { useMessageAttempts } from '../../webhooks/hooks';
import { RiInformationLine } from 'react-icons/ri';
import { toWordsOrdinal } from 'number-to-words';
import capitalize from 'lodash/capitalize';
import { useMemo } from 'react';
import { WebhookMessageStatusTag } from '../../components/Tag/WebhookMessageStatusTag';
import { webhookStatusToVariant } from '../../constant/status';

const EventMessageDetail = ({
  message,
  attempts,
}: {
  message: WebhookMessage;
  attempts: number;
}) => {
  const attemptsTooltip = useMemo(() => {
    if (!message.nextAttempt) {
      return;
    }

    const nthAttempt = capitalize(toWordsOrdinal(attempts));
    const diff = subMilliseconds(
      new Date(message.nextAttempt),
      now(),
    ).getTime();
    let timeToRetry = millisecondsToMinutes(diff);
    let unit = 'minute';

    if (timeToRetry <= 0) {
      timeToRetry = millisecondsToSeconds(diff);
      unit = 'second';
    } else if (timeToRetry >= 60) {
      timeToRetry = millisecondsToHours(diff);
      unit = 'hour';
    }

    timeToRetry = Math.max(0, timeToRetry);

    const plural = new Intl.PluralRules('en-US');

    if (plural.select(timeToRetry) === 'other') {
      unit = `${unit}s`;
    }

    return `${nthAttempt} attempt. If this attempt fails, we will try again in ${timeToRetry} ${unit}.`;
  }, [attempts, message]);

  return (
    <Box
      borderRadius="8px"
      overflow="hidden"
      boxShadow="0px 4px 8px 0px #1616160F, 0px 0px 1px 0px #1616161F"
      backgroundColor={COLORS.WHITE}
    >
      <Box p="6">
        <Stack direction="column" spacing="24px">
          <Stack direction="row" align="center">
            <Box>
              <Text
                fontFamily="Inter"
                fontWeight={500}
                fontSize="14px"
                lineHeight="17px"
                color={COLORS.FINCH.BLACK}
                width="100px"
              >
                Event ID
              </Text>
            </Box>
            <Text
              fontFamily="Inter"
              fontWeight={400}
              fontSize="14px"
              lineHeight="17px"
              color={COLORS.FINCH.BLACK}
            >
              {message.id}
            </Text>
          </Stack>
          <Stack direction="row" align="center">
            <Box>
              <Text
                fontFamily="Inter"
                fontWeight={500}
                fontSize="14px"
                lineHeight="17px"
                color={COLORS.FINCH.BLACK}
                width="100px"
              >
                Timestamp
              </Text>
            </Box>
            <Box isTruncated>
              <Caption>{format(new Date(message.timestamp))}</Caption>
            </Box>
          </Stack>
          <Stack direction="row" align="center">
            <Box>
              <Text
                fontFamily="Inter"
                fontWeight={500}
                fontSize="14px"
                lineHeight="17px"
                color={COLORS.FINCH.BLACK}
                width="100px"
              >
                Event Type
              </Text>
            </Box>
            <Tag color={COLORS.FINCH.PURPLE}>{message.eventType}</Tag>
          </Stack>
        </Stack>
      </Box>
      <Divider />
      <Box p="6">
        <Stack direction="column" spacing="24px">
          <Stack direction="row" align="center">
            <Box>
              <Text
                fontFamily="Inter"
                fontWeight={500}
                fontSize="14px"
                lineHeight="17px"
                color={COLORS.FINCH.BLACK}
                width="100px"
              >
                Status
              </Text>
            </Box>
            <Box>
              <WebhookMessageStatusTag
                variant={webhookStatusToVariant[message.status]}
              />
            </Box>
          </Stack>
          <Stack direction="row" align="center">
            <Box>
              <Text
                fontFamily="Inter"
                fontWeight={500}
                fontSize="14px"
                lineHeight="17px"
                color={COLORS.FINCH.BLACK}
                width="100px"
              >
                Attempts
              </Text>
            </Box>
            <Box isTruncated>
              <HStack align="center">
                <Caption>{attempts}</Caption>
                {message.nextAttempt && (
                  <Tooltip hasArrow label={attemptsTooltip}>
                    <span>
                      <RiInformationLine size="16px" />
                    </span>
                  </Tooltip>
                )}
              </HStack>
            </Box>
          </Stack>
          {message.nextAttempt && (
            <Stack direction="row" align="center">
              <Box>
                <Text
                  fontFamily="Inter"
                  fontWeight={500}
                  fontSize="14px"
                  lineHeight="17px"
                  color={COLORS.FINCH.BLACK}
                  width="100px"
                >
                  Next Attempt
                </Text>
              </Box>
              <Box isTruncated>
                <Caption>{format(new Date(message.nextAttempt))}</Caption>
              </Box>
            </Stack>
          )}
        </Stack>
      </Box>
      <Divider />
      <Box p="6">
        <ReactJson
          src={message.payload as object}
          name={'payload'}
          collapsed={false}
          collapseStringsAfterLength={40}
          enableClipboard={false}
          displayObjectSize={false}
          displayDataTypes={false}
        />
      </Box>
    </Box>
  );
};

export const EventDetailDrawer = ({
  message,
  applicationId,
  endpointId,
  isOpen,
  onClose,
}: {
  message: WebhookMessage;
  applicationId: string;
  endpointId: string;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { loading, error, attempts } = useMessageAttempts(
    applicationId,
    endpointId,
    message.id,
  );

  return (
    <Drawer isOpen={isOpen} onClose={onClose} size="lg">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader
          padding="22px 24px"
          borderBottomWidth="1px"
          fontSize="18px"
          fontFamily="Inter"
        >
          <Flex>
            <span>Event Details</span>
            <Spacer />
            <CloseButton onClick={onClose} width="24px" height="24px" />
          </Flex>
        </DrawerHeader>

        <DrawerBody p="6" backgroundColor={COLORS.GRAY.GRAY_100}>
          {loading && (
            <AbsoluteCenter>
              <Spinner />
            </AbsoluteCenter>
          )}
          {!loading && (
            <EventMessageDetail
              message={message}
              attempts={error ? 0 : attempts}
            />
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
