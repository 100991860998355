import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Button,
  Text,
  Box,
  Stack,
  Input,
  FormErrorMessage,
  FormControl,
} from '@chakra-ui/react';
import { COLORS } from '../../constant';
import { AlertBodyText } from '../../shared/Typography';
import { useState } from 'react';
import { useConnectionDisconnect } from '../hooks/useConnectionDisconnect';

const DISCONNECT_CONFIRMATION_TEXT = 'DISCONNECT CONNECTION';

export const DisconnectConfirmationModal = ({
  isOpen,
  onClose,
  companyName,
  clientId,
  connectionId,
  onConnectionDisconnected,
}: {
  isOpen: boolean;
  onClose: () => void;
  companyName: string;
  clientId: string;
  connectionId: string;
  onConnectionDisconnected: () => void;
}) => {
  const mutation = useConnectionDisconnect({
    applicationId: clientId,
    connectionId,
  });
  const [disconnectConfirmationText, setDisconnectConfirmationText] =
    useState('');
  const [isDisconnectButtonDisabled, setIsDisconnectButtonDisabled] =
    useState(true);

  const warningText = (
    <Stack gap="24px">
      <Text>
        You are about to disconnect{' '}
        <Text as="span" fontWeight="bold">
          {companyName}
        </Text>{' '}
        from your account. If any processes or integrations rely on this
        connection, they will stop functioning correctly. You will also no
        longer be able to access or sync data with this company.
      </Text>
      <Text>Are you sure you want to proceed?</Text>
      <Text>
        Type{' '}
        <Text as="span" fontWeight="bold">
          {`"${DISCONNECT_CONFIRMATION_TEXT}”`}
        </Text>{' '}
        without quotations to confirm.
      </Text>
    </Stack>
  );

  const onDisconnectConfirmationTextChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setDisconnectConfirmationText(e.target.value);
    setIsDisconnectButtonDisabled(
      !(e.target.value === DISCONNECT_CONFIRMATION_TEXT),
    );
  };

  const disconnect = async () => {
    await mutation.mutateAsync();
    setDisconnectConfirmationText('');
    setIsDisconnectButtonDisabled(true);
    onConnectionDisconnected();
    onClose();
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered size="lg">
        <ModalOverlay />
        <ModalContent maxWidth={'576px'}>
          <ModalHeader width={'100%'}>
            <Box
              fontFamily="Inter"
              fontSize="18px"
              fontWeight="semibold"
              color={COLORS.FINCH.RED}
              textAlign={'center'}
              padding={'12px'}
              width={'100%'}
              backgroundColor="error.c100"
              borderRadius={'8px'}
              lineHeight="16px"
            >
              Confirm Disconnection
            </Box>
          </ModalHeader>

          <ModalBody>
            <Box>
              <Stack gap="24px">
                <AlertBodyText>{warningText}</AlertBodyText>
                <FormControl
                  isInvalid={
                    disconnectConfirmationText !== '' &&
                    disconnectConfirmationText !== DISCONNECT_CONFIRMATION_TEXT
                  }
                >
                  <Input
                    placeholder={DISCONNECT_CONFIRMATION_TEXT}
                    value={disconnectConfirmationText}
                    onChange={onDisconnectConfirmationTextChange}
                  />
                  <FormErrorMessage>
                    The text entered does not match
                    {` "${DISCONNECT_CONFIRMATION_TEXT}"`}
                  </FormErrorMessage>
                </FormControl>
              </Stack>
            </Box>
          </ModalBody>

          <ModalFooter>
            <Box display="flex" gap="16px">
              <Button size="md" variant="outline" onClick={onClose}>
                Cancel
              </Button>
              <Button
                variant="danger"
                isLoading={false}
                isDisabled={isDisconnectButtonDisabled}
                onClick={disconnect}
              >
                Disconnect
              </Button>
            </Box>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
